import React, {useEffect} from 'react';
import "moment/locale/fi";
import { useAuth } from '../Auth/Auth.js'

import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FileInput from '../Components/FileInput.js';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Badge from '@material-ui/core/Badge';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import { DatePicker } from "@material-ui/pickers";
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LinkButton from '../Components/LinkButton.js'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Alert, AlertTitle } from '@material-ui/lab';
import InputLabel from '@material-ui/core/InputLabel';
import Modal from '@material-ui/core/Modal';
import HelpIcon from '@material-ui/icons/Help';
import CancelIcon from '@material-ui/icons/Cancel';
import { ContactSupportOutlined } from '@material-ui/icons';


export default function OrganicGoal(props) {

	return (
		<>

        <Typography className={props.classes.maintText}>Kerro tässä, mille portaalle hakeudutte ja perustele. Kerro myös, kuinka paljon teillä tavoitteena lisätä luomun käyttöä.</Typography>

        <Grid container spacing={3}>
            <Grid item xs={2}>
                <FormControl variant="outlined" fullWidth className={props.classes.formInput}>
                    <InputLabel id="APPLYFORSTEP-select-label">Hakeudutaan portaalle *</InputLabel>
                    <Select
                        id="APPLYFORSTEP"
                        name="APPLYFORSTEP"
                        labelId="APPLYFORSTEP-select-label"
                        variant="outlined"
                        className={props.classes.formInput}
                        type="text"
                        inputProps={{ readOnly: props.readOnly }}
                        value={props.planData.APPLYFORSTEP}
                        onChange={props.handleSelectInputChange}
                        error={!props.isValid && (props.planData.APPLYFORSTEP=="" || props.planData.APPLYFORSTEP==null)}
                        >
                        <MenuItem key={1} value={"1"}>1</MenuItem>
                        <MenuItem key={2} value={"2"}>2</MenuItem>
                        <MenuItem key={3} value={"3"}>3</MenuItem>
                        <MenuItem key={4} value={"4"}>4</MenuItem>
                        <MenuItem key={5} value={"5"}>5</MenuItem>
                        <MenuItem key={6} value={"6"}>6</MenuItem>
                    </Select>
                </FormControl>
            </Grid>
            <Grid item xs={10}>
                <Typography className={props.classes.extraInfoText}>Katso ja tutustu <Link href="http://www.luomuravintola.fi/portaiden-kriteerit" target="_blank">porrastasojen vaatimuksiin</Link></Typography>
            </Grid>
        </Grid>	


        <TextField
            id="CRITERIAFORSTEP"
            label="Perustelut *"
            variant="outlined"
            className={props.classes.formInput}
            type="text"
            value={props.planData.CRITERIAFORSTEP}
            fullWidth
            multiline
            rows="8"
            helperText="Valitse porras jolle organisaatio hakeutuu sekä perustele valintasi"
            onChange={props.handleTextInputChange}
            inputProps={{ readOnly: props.readOnly }}
            InputLabelProps={{
                shrink: true,
            }}
            error={!props.isValid && (props.planData.CRITERIAFORSTEP=="" || props.planData.CRITERIAFORSTEP==null)}
        />

        <TextField
            id="DECISIONPERCENT"
            label="Kuinka paljon luomun käyttöä on tavoitteena lisätä? *"
            variant="outlined"
            className={props.classes.formInputSmall}
            type="number"
            value={props.planData.DECISIONPERCENT}
            InputProps={{
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
                readOnly: props.readOnly
            }}
            onChange={props.handleTextInputChange}
            InputLabelProps={{
                shrink: true,
            }}
            error={!props.isValid && (props.planData.DECISIONPERCENT=="" || props.planData.DECISIONPERCENT==null)}
        />

        <hr/>
        <h4 className={props.classes.mainHeader}>Strategiset päätökset</h4>

        <FormControl component="fieldset" fullWidth>
            <FormLabel component="legend">Onko ruokapalveluissa/ravintolassa tehty strateginen päätös luomutuotteiden käytön lisäämiseksi ruokapalveluissa? *</FormLabel>
            <FormControlLabel className={props.classes.checkboxLabel} control={<Checkbox checked={props.planData.DECISIONMUNICIPALITY} onChange={props.handleSwitchChange} id="DECISIONMUNICIPALITY" name="DECISIONMUNICIPALITY" />} label="Kunnassa" />
            <FormControlLabel className={props.classes.checkboxLabel} control={<Checkbox checked={props.planData.DECISIONORGANISATION} onChange={props.handleSwitchChange} id="DECISIONORGANISATION" name="DECISIONORGANISATION" />} label="Organisaatiossa" />
        </FormControl>

        <TextField
            id="DECISIONYEAR"
            label="Mihin vuoteen mennessä on tarkoitus saavuttaa päätöksen mukainen tavoite?"
            variant="outlined"
            className={props.classes.formInput}
            type="text"
            fullWidth
            value={props.planData.DECISIONYEAR}
            InputProps={{
                startAdornment: <InputAdornment position="start">vuoteen</InputAdornment>,
                endAdornment: <InputAdornment position="end">mennessä</InputAdornment>,
                readOnly: props.readOnly
            }}
            onChange={props.handleTextInputChange}
            InputLabelProps={{
                shrink: true,
            }}
        />

        <TextField
            id="FOODTENDERING_PRODUCTS"
            label="Seuraavaksi hankittavat luomutuotteet"
            variant="outlined"
            className={props.classes.formInput}
            type="text"
            value={props.planData.FOODTENDERING_PRODUCTS}
            fullWidth
            multiline
            rows="4"
            onChange={props.handleTextInputChange}
            inputProps={{ readOnly: props.readOnly }}
            InputLabelProps={{
                shrink: true,
            }}
        />	

        <TextField
            id="FOODTENDERING_START"
            label="Seuraavan elintarvikekilpailutuksen suunnittelu alkaa (julkinen sektori)"
            variant="outlined"
            className={props.classes.formInput}
            type="text"
            value={props.planData.FOODTENDERING_START}
            fullWidth
            onChange={props.handleTextInputChange}
            inputProps={{ readOnly: props.readOnly }}
            InputLabelProps={{
                shrink: true,
            }}
        />	

        <TextField
            id="FOODTENDERING_TIME"
            label="Seuraavan elintarvikehankinnan ajankohta (julkinen sektori)"
            variant="outlined"
            className={props.classes.formInput}
            type="text"
            value={props.planData.FOODTENDERING_TIME}
            fullWidth
            onChange={props.handleTextInputChange}
            inputProps={{ readOnly: props.readOnly }}
            InputLabelProps={{
                shrink: true,
            }}
        />	

        
        </>
					
	);
}
