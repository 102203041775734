import React, { useState, useEffect } from "react";
import { useAuth } from '../Auth/Auth'
import "moment/locale/fi";
import { makeStyles} from '@material-ui/core/styles';
import axios from 'axios';
import plconfig from '../config.js'
import FileInput from '../Components/FileInput';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';

import { Alert, AlertTitle } from '@material-ui/lab';

import Modal from '@material-ui/core/Modal';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Link from '@material-ui/core/Link';
import CancelIcon from '@material-ui/icons/Cancel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import DeleteIcon from '@material-ui/icons/Delete';

const useStyles = makeStyles((theme) => ({
	grow: {
		flexGrow: 1,
		marginBottom: 80
	},
	gridRight: {
		textAlign: 'right'
	},
	modalPaper: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		width: '80%',
		maxWidth: 1600,
		minWidth: 800,
		height: '80%',
		maxHeight: 1200,
		minHeight: 670,
		backgroundColor: theme.palette.background.paper,
		border: '4px solid #c3c3c3',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(4),
	},
	modalCloseButton: {
		position: 'absolute',
		top: 5,
		right: 5,
	},
	bottomGridRight: {
		textAlign: 'right'
	},
	paper: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '600',
		padding: theme.spacing(3),
		paddingTop: theme.spacing(4),
		marginBottom: theme.spacing(3)
	},
	PaperHeader: {
		marginBottom: 16
	},
	ImportTitle: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '500',
		fontSize: '1.3em'
	},
	PaperTitle: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '500',
	},
	PaperText: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '400',
	},
	muted: {
		fontSize: '0.8em',
		fontWeight: '300',
		color: theme.palette.text.muted
	},
	commonAlert: {
		marginTop: theme.spacing(2),
		marginLeft: theme.spacing(3),
		marginRight: theme.spacing(3),
		marginBottom: theme.spacing(2)
	},
	productListContainer: {
		paddingBottom: theme.spacing(4),
		paddingLeft: theme.spacing(4),
		paddingTop: theme.spacing(0),
		paddingRight: theme.spacing(3)
	},
	productList: {
		marginBottom: theme.spacing(4),
		overflow: 'auto',
		height: '40vh'
	},
	productListItem: {
		margin: theme.spacing(0),
		padding: theme.spacing(0),
	},
	productListBadge: {
		marginRight: theme.spacing(3),
	},
	tukkuList: {
		paddingTop: theme.spacing(2),
		paddingBottom: theme.spacing(4),
		overflow: 'auto',
		height: '25vh'
	}
}));

function capitalizeFirstLetter(string) {
	if (string) {
		return string.charAt(0).toUpperCase() + string.slice(1);
	} else {
		return string
	}
}

function calcPros(total, eko) {
	var pros = Math.ceil(((eko / total) * 100))
	if (pros>100) {
		pros = 100
	}
	return pros.toFixed(0)
};

function getImporterDate(importer) {
	var datestr = importer.datemonth + " / " + importer.dateyear
	if (importer.type === "jamix") {
		datestr = importer.created
	}
	return datestr
};


export default function ImportChooser(props) {
	const classes = useStyles();

	//const existingTokens = JSON.parse(localStorage.getItem("tokens"));
	const { isAuthenticated, setIsAuthenticated, userData, setUserData } = useAuth();

	const [selectedProduct, setSelectedProduct] = React.useState("");
	const [selectedImporter, setSelectedImporter] = React.useState("");

	const [importTotalCheck, setImportTotalCheck] = React.useState(true);
	const handleImportTotalCheckChange = (event) => {
		setImportTotalCheck(event.target.checked);
	};

	const [changeImportModalOpen, setChangeImportModalOpen] = React.useState(false);
	const handleChangeImportModalOpen = () => {
		if (props.planid == 'new') {
			alert('Ole hyvä ja tallenna luomusuunnitelma alalaidasta olevasta Tallenna-painikkeesta ennen tuotetietojen tuomista.')
		} else {
			setShowProductsData(false)
			setChangeImportModalOpen(true)
		}
	};
	const handleChangeImportModalClose = () => {
		setChangeImportModalOpen(false);
	};

	const [showProductsData, setShowProductsData] = React.useState(false);
	const showProductsDataHandler = (importer) => {
		setSelectedImporter(importer)
		setShowProductsData(true)
		setIsUploadError(false)
	};

	const [isUploadError, setIsUploadError] = React.useState(false);

	const [importsdb, setImportsdb] = React.useState([]);
	useEffect(() => {
		if (props.chainid) {
			setIsUploadError(false)
			getImports();
		}
	}, [props.chainid])


	const getImports = () => {
		axios.post(plconfig.APIBase + 'apps/import_get.groovy?action=info', {
			id: props.chainid
		}, { headers: { "Content-Type": "text/plain" } })
		.then(function (response) {
			if (response.data.success) {
				setImportsdb(response.data)
			} else {
			}
		})
		.catch(function(error) {
		});
	};


	const handleImport = () => {
		var noconfirm = true;
		if (selectedImporter.importedAt) {
			noconfirm = false;
		}

		if (noconfirm || confirm("Haluatko varmasti tuoda tiedot uudestaan tuontitiedoista '" + capitalizeFirstLetter(selectedImporter.type) + " " + getImporterDate(selectedImporter) + "'?")) {
			axios.post(plconfig.APIBase + 'apps/import_get.groovy?action=markused', {
				id: props.chainid,
				planid: props.planid,
				importerid: selectedImporter.id
			}, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					props.handleImport(importTotalCheck, selectedImporter)
					handleChangeImportModalClose();
				} else {
					setIsUploadError(true)
				}
			})
			.catch(function(error) {
			});
		}
		
	};

	const deleteImporter = (importer) => {
		if (confirm("Haluatko varmasti poistaa tuontitiedot '" + capitalizeFirstLetter(importer.type) + " " + getImporterDate(importer) + "'?")) {
			axios.post(plconfig.APIBase + 'apps/import_get.groovy?action=delete', {
				id: props.chainid,
				importerid: importer.id
			}, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setShowProductsData(false)
					setSelectedImporter("")
					getImports()
				} else {
				}
			})
			.catch(function(error) {
			});
		}
		
	};

	const uploadFile = (file) => {
		setIsUploadError(false)

		const data = new FormData() 
        data.append('file', file)
		data.append('id', props.chainid);
		axios.post(plconfig.APIBase + 'apps/parseimports.groovy?action=upload', data)
		.then(function (response) {
			if (response.data.success) {
				getImports();
			} else {
				// show errors
				setIsUploadError(true)
			}
		})
		.catch(function(error) {
		});
	};

	return (
		<div className={classes.chainselectbar}>
			{(userData && userData.chains) &&
				<React.Fragment>

					<Button variant="outlined" color="primary" className={classes.bottombutton} onClick={handleChangeImportModalOpen} disabled={props.readOnly}>Tuo tuotetietoja</Button>

					<Modal
						id="ImportModal"
						open={changeImportModalOpen}
						onClose={handleChangeImportModalClose}>
						<div className={classes.modalPaper}>
							<IconButton className={classes.modalCloseButton} onClick={handleChangeImportModalClose}><CancelIcon /></IconButton>
							<h4 className={classes.PaperHeader}>Tuo tuotteiden käyttötietoja ohjelman ulkopuolelta</h4>
							

							<Grid container spacing={3} className={classes.bottomGrid2}>
								<Grid item xs={4}>

									<Typography className={classes.maintText}>Tällä työkalulla voit tuoda ohjelmaan luomutuotteiden käyttötietoja muista ohjelmista ja/tai tukkujen ostotiedoista. Lue lisää ja aloita sopimuksen teko <Link href="https://www.luomuravintola.fi/katsy-ohje" target="_blank">KÄTSY-ohjesivulta</Link></Typography>
									<hr />

									<p className={classes.PaperTitle}>Tuodut siirtotiedot</p>

									<FileInput fileHandler={uploadFile} btntext="Lataa siirtotiedosto"/>

									<div className={classes.tukkuList}>
									{(importsdb && importsdb.importers) &&
										<React.Fragment>
											{importsdb.importers.map((importer) =>
												<ListItem key={importer.id} button selected={selectedImporter.id===importer.id} onClick={() => showProductsDataHandler(importer)}>
													<ListItemText 
														primary={capitalizeFirstLetter(importer.type) + " " + getImporterDate(importer)} 
														secondary={
															<React.Fragment>Yhteensä {importer.totalcount} kpl / {importer.totalweight.toFixed(0)} kg
																{importer.importedAt && 
																	<i> (tuotu viimeksi {importer.importedAt})</i>
																}
																<ListItemSecondaryAction>
																	<IconButton edge="end" aria-label="delete" onClick={() => deleteImporter(importer)}>
																		<DeleteIcon />
																	</IconButton>
																</ListItemSecondaryAction>
															</React.Fragment>
													} />
													
												</ListItem>
											)}
										</React.Fragment>
									}
									</div>

								</Grid>
								<Grid item xs={8}>

									{isUploadError &&
										<Alert severity="alert" className={classes.commonAlert}>
											<AlertTitle>Siirtotiedoston käsittely ei onnistunut</AlertTitle>
											Katsothan tarkasti että siirtotiedoston muoto on sama kuin <Link href="https://www.luomuravintola.fi/katsy-ohje" target="_blank">KÄTSY-ohjesivulta</Link> löytyvien mallitiedostojen. Erityisesti sarakkeet täytyy olla samalla kohdalla.
										</Alert>
									}

									{showProductsData &&
										<div id="productListContainer" className={classes.productListContainer}>

											<p className={classes.ImportTitle}>{capitalizeFirstLetter(selectedImporter.type)} {getImporterDate(selectedImporter)}</p>
											<p className={classes.PaperText}>Kaikkia tuotteita yhteensä <strong>{selectedImporter.totalweight.toFixed(2)} kg</strong> joista luomutuotteita <strong>{selectedImporter.ekoweight.toFixed(2)} kg</strong>. Luomuprosentti on <strong>{calcPros(selectedImporter.totalweight, selectedImporter.ekoweight)} %</strong></p>
											<p className={classes.PaperText}>Lisättävät luomutuotteet</p>
											<List component="nav" aria-label="Luomutuotteet" dense={true} className={classes.productList}>
												{(selectedImporter.productdata) &&
													<React.Fragment>
													{selectedImporter.productdata.split('\n').map((product) =>
														<React.Fragment>
															<ListItem key={product.id}><ListItemText className={classes.productListItem} primary={product.replace('\r', '')} /></ListItem>
														</React.Fragment>
													)}
													</React.Fragment>
												}
											</List>	

											<Grid container spacing={3} className={classes.bottomGrid2}>
												<Grid item xs={4}>
													<Button variant="contained" color="primary" className={classes.bottombutton} onClick={() => handleImport()}>Tuo tiedot lomakkeelle</Button>
												</Grid>
												<Grid item xs={8}>
													<FormGroup>
														<FormControlLabel control={<Checkbox checked={importTotalCheck} onChange={handleImportTotalCheckChange}/>} label="Lisää myös kokonaispaino" />
													</FormGroup>
												</Grid>
											</Grid>	
											
										</div>
									}
									
								</Grid>
							</Grid>	

						</div>
					</Modal>
					
				</React.Fragment>
			}
		</div>
	);
}