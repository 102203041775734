import React, {useEffect} from 'react';
import "moment/locale/fi";
import axios from 'axios';
import plconfig from '../config.js'

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FileInput from '../Components/FileInput.js';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Badge from '@material-ui/core/Badge';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import { DatePicker } from "@material-ui/pickers";
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LinkButton from '../Components/LinkButton.js'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Alert, AlertTitle } from '@material-ui/lab';
import InputLabel from '@material-ui/core/InputLabel';
import Modal from '@material-ui/core/Modal';
import HelpIcon from '@material-ui/icons/Help';
import CancelIcon from '@material-ui/icons/Cancel';

export default function WildProducts(props) {

    const [selectedWildProductGroup, setSelectedWildProductGroup] = React.useState("");
	const [selectedWildProduct, setSelectedWildProduct] = React.useState("");

    const [selectedWildProductGradient, setSelectedWildProductGradient] = React.useState({
		USAGE: 'nousage',
		NAME: ''
	});

    // WILDPRODUCT INPUT HANDLERS
	const handleWildProductInputChange = (event) => {
        if (!props.readOnly) {

            //console.log(event.target.name + ' : ' + event.target.value)

            setSelectedWildProductGradient({
                ...selectedWildProductGradient,
                [event.target.id]: event.target.value
            });

            // update original gradient also
            let og = props.planData.WILDPRODUCTS;
            if (!og) {
                og = []
            }

            // clean if nousage
            if (event.target.value==='nousage') {

                let found = false;
                let foundindex = -1;
                for (const gradient in og) {
                    if (og[gradient].PRODUCTID === selectedWildProductGradient.PRODUCTID) {
                        //og[gradient] = selectedWildProductGradient
                        og[gradient][event.target.id] = event.target.value
                        found = true;
                        foundindex = gradient;
                    }
                }
                // if found remove from list
                if (found) {
                    og.splice(foundindex, 1)
                    setSelectedWildProductGradient({
                        USAGE: 'nousage',
                        NAME: ''
                    });
                }
                props.setPlanData({
                    ...props.planData,
                    ["WILDPRODUCTS"]: og
                });

            } else {

                let found = false;
                for (const gradient in og) {
                    if (og[gradient].PRODUCTID === selectedWildProductGradient.PRODUCTID) {
                        //og[gradient] = selectedWildProductGradient
                        og[gradient][event.target.id] = event.target.value
                        found = true;
                    }
                }
                // if not found add clone from selected
                if (!found) {
                    const clone = JSON.parse(JSON.stringify(selectedWildProductGradient));
                    clone[event.target.id] = event.target.value
                    og.push(clone)
                    //console.log(clone)
                }
                //console.log(og);

                props.setPlanData({
                    ...props.planData,
                    ["WILDPRODUCTS"]: og
                });

            }
            

            props.setPlanSaved(false)

        }
	};

    // WILD PRODUCT DISPLAY AND SELECT HANDLERS
	const selectWildProductGroup = (newValue) => {
		setSelectedWildProductGroup(newValue.id);
	};
	const selectWildProduct = (product) => {
		
		let newg = null;
		setSelectedWildProduct(product.id);
		if (props.planData.WILDPRODUCTS) {
			for (const gradient in props.planData.WILDPRODUCTS) {
				let g = props.planData.WILDPRODUCTS[gradient]
				if (g.PRODUCTID === product.id) {
					newg = g
					newg.NAME = product.NAME
				}
			}
		}
		if (newg) {
			setSelectedWildProductGradient(newg)
		} else {
			setSelectedWildProductGradient({
				USAGE: 'nousage',
				PRODUCTID: product.id,
				NAME: product.NAME
			})
		}
	};
	const checkwildproductcount = (productid) => {
		var count = 0
		if (props.planData.WILDPRODUCTS) {
			for (const gradient in props.planData.WILDPRODUCTS) {
				if (props.planData.WILDPRODUCTS[gradient].PRODUCTID === productid) {
					//count = props.planData.ORGANICINGREDIENTS[gradient].RATE + ' ' +  props.planData.ORGANICINGREDIENTS[gradient].UNITOFRATE
					count++
				}
			}
		}
		return <Badge color="secondary" badgeContent={count} />
	}
	const checkwildproductgroupcount = (productgroup) => {
		var count = 0
		if (props.planData.WILDPRODUCTS) {
			for (const gradient in props.planData.WILDPRODUCTS) {
				for (const product in wildproductsdb.wildproducts) {
					if (wildproductsdb.wildproducts[product].GROUP === productgroup.id) {
						if (props.planData.WILDPRODUCTS[gradient].PRODUCTID === wildproductsdb.wildproducts[product].id) {
							count++
						}
					}
				}
			}
		}
		return <Badge color="secondary" badgeContent={count} />
	}


    const [wildproductsdb, setWildProductsdb] = React.useState([]);
	useEffect(() => {
		if (props.chainid) {
			axios.get(plconfig.APIBase + 'apps/api_getwildproducts.groovy', { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setWildProductsdb(response.data)
				} else {
				}
			})
			.catch(function(error) {
			});
		}
	}, [props.chainid])


	return (
		<>

            <Typography className={props.classes.maintText}>Merkkaa tähän kohtaan kotimaiset villi- ja keruutuotteet, joita käytätte ja <Link href="https://www.ruokavirasto.fi/yritykset/elintarvikeala/luomutuotteet/luomukeruutuotteet-luomukeruualueilta/" target="_blank">jotka eivät ole sertifioitua luomua.</Link>
            <br/>Tuotteet voivat olla tuoreita tai pakasteita. Kun käytössä on säännöllisesti vähintään kahden villituoteryhmän tuotetta, voitte ottaa käyttöönne Käytämme luonnontuotteita -merkin.</Typography>

            {props.planData.ISWILDPRODUCTMARK &&
                <Typography className={props.classes.maintText}>Voit ladata merkin <Link href="https://www.luomuravintola.fi/resources/images//luomua_ravintolat_luonnontuote_tunnus_v2.jpg" target="_blank">JPG</Link> tai <Link href="https://www.luomuravintola.fi/resources/images//luomua_ravintolat_luonnontuote_tunnus_v2.pdf" target="_blank">PDF-tiedostona</Link></Typography>
            }

            <Grid container spacing={2}>
                <Grid item xs={3} className={props.classes.productColumn1}>
                    <Typography className={props.classes.productHeader}>Villi- ja keruutuotteet</Typography>
                    <List component="nav" aria-label="Villi- ja keruutuotteet" dense={false}>
                        {(wildproductsdb && wildproductsdb.wildproductgroups) &&
                            <React.Fragment>
                            {wildproductsdb.wildproductgroups.map((wildproductgroup) =>
                                <ListItem key={wildproductgroup.id} button selected={selectedWildProductGroup===wildproductgroup.id} onClick={() => selectWildProductGroup(wildproductgroup)}><ListItemText primary={wildproductgroup.NAME} />
                                    {checkwildproductgroupcount(wildproductgroup)}
                                </ListItem>
                            )}
                            </React.Fragment>
                        }
                    </List>	
                </Grid>
                <Grid item xs={3} className={props.classes.productColumn2}>
                    <Typography className={props.classes.productHeader}>Tuotteet</Typography>
                    <List component="nav" aria-label="Luomutuoteryhmät" dense={false}>
                        {(wildproductsdb && wildproductsdb.wildproducts) &&
                            <React.Fragment>
                            { wildproductsdb.wildproducts.map((wildproduct) =>
                                <React.Fragment>
                                    {wildproduct.GROUP === selectedWildProductGroup &&
                                        <ListItem button selected={selectedWildProduct===wildproduct.id} onClick={() => selectWildProduct(wildproduct)}><ListItemText primary={props.capitalizeFirstLetter(wildproduct.NAME)} />
                                            {checkwildproductcount(wildproduct.id)}
                                        </ListItem>
                                    }
                                </React.Fragment>
                            )}
                            </React.Fragment>
                        }
                    </List>	
                </Grid>
                <Grid item xs={6} className={props.classes.productColumn3}>

                    {(selectedWildProductGradient && selectedWildProductGradient.NAME!=='') &&
                    <div className={props.classes.productForm}>

                        <Typography className={props.classes.productHeader2}>Tuotteen "{selectedWildProductGradient.NAME}" käyttötiedot</Typography>

                        <FormControl component="fieldset" fullWidth>
                            <FormLabel component="legend">Tuotetta käytetään</FormLabel>
                            <RadioGroup aria-label="usage" id="USAGE" name="USAGE"  value={selectedWildProductGradient.USAGE} onChange={handleWildProductInputChange}>
                                <FormControlLabel value="nousage" control={<Radio id="USAGE" checked={selectedWildProductGradient.USAGE === 'nousage'} value="nousage" />} label="Tuote ei käytössä (poistetaan listauksesta)" />
                                <FormControlLabel value="allthetime" control={<Radio id="USAGE" checked={selectedWildProductGradient.USAGE === 'allthetime'} value="allthetime"/>} label="Käytetään jatkuvasti" />
                                <FormControlLabel value="twiceweek" control={<Radio id="USAGE" checked={selectedWildProductGradient.USAGE === 'twotimes'} value="twotimes"/>} label="Kaksi kertaa viikossa" />
                                <FormControlLabel value="other" control={<Radio id="USAGE" checked={selectedWildProductGradient.USAGE === 'rarely'} value="rarely" />} label="Harvemmin" />
                            </RadioGroup>
                        </FormControl>

                    </div>
                    }

                </Grid>
            </Grid>	
        
        </>
					
	);
}
