import React, {useEffect} from 'react';
import "moment/locale/fi";
import { useAuth } from '../Auth/Auth.js'
import axios from 'axios';
import moment from "moment";
import "moment/locale/fi";

import plconfig from '../config.js'

import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FileInput from '../Components/FileInput.js';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Badge from '@material-ui/core/Badge';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import { DatePicker } from "@material-ui/pickers";
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LinkButton from '../Components/LinkButton.js'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Alert, AlertTitle } from '@material-ui/lab';
import InputLabel from '@material-ui/core/InputLabel';
import Modal from '@material-ui/core/Modal';
import HelpIcon from '@material-ui/icons/Help';
import CancelIcon from '@material-ui/icons/Cancel';
import { ContactSupportOutlined } from '@material-ui/icons';


export default function Producers(props) {

    const [selectedProducer, setSelectedProducer] = React.useState({
		PRODUCERIDENTIFIER: '',
		PRODUCERVALIDITYDATE: null,
		PRODUCER: ""
	});

    // PRODUCER DISPLAY AND SELECT HANDLERS
	const selectProducer = (newProducer, index) => {
		if (newProducer.PRODUCERIDENTIFIER) {
			newProducer.PRODUCERIDENTIFIER = newProducer.PRODUCERIDENTIFIER.toUpperCase();
		}

		// get certfile by name
		producersdb.producers.map((producerItem) => {
			if (producerItem.NAME==newProducer.PRODUCER) {
				newProducer.CERT = producerItem.CERT;
				if (producerItem.VALIDITYDATESTR) {
					newProducer.PRODUCERVALIDITYDATE = moment(producerItem.VALIDITYDATESTR, "DD.MM.YYYY");
				}
			}
		});

		setSelectedProducer(newProducer);
		
	};

	const producers = props.planData.PRODUCERS.map((producer, index) =>
		<React.Fragment>
			<ListItem key={producer.PRODUCERID + " " + index} button selected={selectedProducer===producer} onClick={() => selectProducer(producer, index)}>
				{producer.VKPRODUCER
				? <ListItemText primary={props.capitalizeFirstLetter(producer.VKPRODUCER)} secondary={""} />
				: <ListItemText primary={props.capitalizeFirstLetter(producer.PRODUCER)} secondary={producer.PRODUCERIDENTIFIER} />
				}
				<ListItemSecondaryAction>
					<IconButton edge="end" aria-label="delete" onClick={(e) => {deleteProducer(producer)}} disabled={props.readOnly}>{selectedProducer===producer ? <DeleteIcon style={{ color: '#fff' }}/> : <DeleteIcon /> }</IconButton>
				</ListItemSecondaryAction>
			</ListItem>
		</React.Fragment>
	);

    // PRODUCER INPUT HANDLERS
	const handleProducerInputChange = (event) => {
		
		let name = event.target.id
		if (!name) {
			name = event.target.name
		}
		//console.log(name + ' : ' + event.target.value)
		setSelectedProducer({
			...selectedProducer,
			[name]: event.target.value
		});

		// update producerlist 
		let pl = props.planData.PRODUCERS;
		let found = false;
		if (pl) {
			for (const producerindex in pl) {
				if (pl[producerindex].tempid === selectedProducer.tempid) {
					//pl[producerindex] = selectedProducer
					pl[producerindex][name] = event.target.value
					found = true;
				}
			}
		}
		props.setPlanData({
			...props.planData,
			["PRODUCERS"]: pl
		});
		//console.log(props.planData)

		props.setPlanSaved(false)

	};

	const handleProducerNameChange = (event, newValue) => {
		let value = newValue;
		if (!value) {
			value = event.target.value
		}

		// get certfile by name
		let newcert = "";
		let newdate = null;
		producersdb.producers.map((producerItem) => {
			if (producerItem.NAME==value) {
				newcert = producerItem.CERT;
				if (producerItem.VALIDITYDATESTR) {
					newdate = moment(producerItem.VALIDITYDATESTR, "DD.MM.YYYY");
				}
			}
		});

		setSelectedProducer({
			...selectedProducer,
			PRODUCER: value,
			CERT: newcert,
			PRODUCERVALIDITYDATE: newdate
		});

		// update producerlist 
		let pl = props.planData.PRODUCERS;
		let found = false;
		if (pl) {
			for (const producerindex in pl) {
				if (pl[producerindex].tempid === selectedProducer.tempid) {
					//pl[producerindex] = selectedProducer
					pl[producerindex].PRODUCER = value
					found = true;
				}
			}
		}
		props.setPlanData({
			...props.planData,
			["PRODUCERS"]: pl
		});
		//console.log(props.planData)

		props.setPlanSaved(false)
	};


	const handleProducerCheckboxChange = (event) => {

		if (!props.readOnly) {
			//console.log(event.target.id + ' : ' + event.target.checked)
			let currentgroups = selectedProducer.GROUPS;
			if (event.target.checked) {
				if (currentgroups.includes(event.target.id)) {
				} else {
					currentgroups.push(event.target.id);
				}
			} else {
				if (currentgroups.includes(event.target.id)) {
					let found = -1;
					if (currentgroups) {
						for (const group in currentgroups) {
							if (event.target.id==currentgroups[group]) {
								found = group;
							}
						}
					}
					if (found>-1) {
						currentgroups.splice(found, 1);
					}
				}
			}
			setSelectedProducer({
				...selectedProducer,
				["GROUPS"]: currentgroups
			});

			// update producerlist 
			let pl = props.planData.PRODUCERS;
			let found = false;
			if (pl) {
				for (const producerindex in pl) {
					if (pl[producerindex].tempid === selectedProducer.tempid) {
						pl[producerindex] = selectedProducer
						//pl[producerindex].GROUPS = currentgroups
						found = true;
					}
				}
			}
			props.setPlanData({
				...props.planData,
				["PRODUCERS"]: pl
			});
			//console.log(props.planData)

			props.setPlanSaved(false)
		}

	};
	const handleProducerDateChange = (date) => {
		//console.log(event.target.id + ' : ' + event.target.value)
		setSelectedProducer({
			...selectedProducer,
			PRODUCERVALIDITYDATE: date
		});
		// update producerlist 
		let pl = props.planData.PRODUCERS;
		let found = false;
		if (pl) {
			for (const producerindex in pl) {
				if (pl[producerindex].tempid === selectedProducer.tempid) {
					pl[producerindex].PRODUCERVALIDITYDATE = date
					found = true;
				}
			}
		}
		props.setPlanData({
			...props.planData,
			["PRODUCERS"]: pl
		});
		//console.log(props.planData)
		props.setPlanSaved(false)
	};

    
    const addProducer = () => {
		let pd = props.planData;
		let d = new Date();
		let dstr = moment().format("D.M.YYYY");
		let r = Math.floor(Math.random() * 100000) + 1
		if (props.planData.PRODUCERS || (props.planData.PRODUCERS[0] && props.planData.PRODUCERS[0].PRODUCER!=="Uusi toimittaja")) {
			let producer = {
				GROUPS: [],
				PRODUCER: "Uusi toimittaja",
				PRODUCERID: "",
				PRODUCERIDENTIFIER: "FI-EKO-201",
				PRODUCERVALIDITY: null,
				PRODUCERVALIDITYDATE: null,
				VKPRODUCER: "",
				tempid: "" + d.getTime() + r
			}
			setSelectedProducer(producer);
			pd.PRODUCERS.unshift(producer)
			props.setPlanData({
				...props.planData,
				["PRODUCERS"]: props.planData.PRODUCERS
			});
		}
	};
	const deleteProducer = (producer) => {
		let found = -1;
		let producerlist = [...props.planData.PRODUCERS];
		if (producerlist) {
			for (const prd in producerlist) {
				if (producer.tempid==producerlist[prd].tempid) {
					found = prd;
				}
			}
		}
		// found it so remove
		if (found>-1) {
			if (confirm("Haluatko varmasti poistaa toimittajan '" + producer.PRODUCER + "'?")) {
				producerlist.splice(found, 1);
			}
		}

		setSelectedProducer({
			PRODUCERIDENTIFIER: '',
			PRODUCERVALIDITY: '1.1.2021',
			PRODUCER: ""
		})

		props.setPlanData({
			...props.planData,
			["PRODUCERS"]: producerlist
		});
	};

    const uploadLuomutodistusFile = (file) => {
		const data = new FormData() 
        data.append('file', file)
		data.append('planid', props.planData.id);
		data.append('producerid', selectedProducer.tempid);
		axios.post(plconfig.APIBase + 'apps/plan.groovy?action=uploadproducerfile', data)
		.then(function (response) {
			if (response.data.success) {
				let url = response.data.fileurl;
				if (url) {
					setSelectedProducer({
						...selectedProducer,
						["CERT"]: url
					})

					// update producerlist 
					let pl = props.planData.PRODUCERS;
					let found = false;
					if (pl) {
						for (const producerindex in pl) {
							if (pl[producerindex].tempid === selectedProducer.tempid) {
								pl[producerindex].CERT = url
								found = true;
							}
						}
					}
					props.setPlanData({
						...props.planData,
						["PRODUCERS"]: pl
					});
					//console.log(props.planData)
					props.setPlanSaved(false)
				}
			}
		})
		.catch(function(error) {
		});
	};

    const fixSlash = (url) => {
		let newurl = url;
		if (url.indexOf("/")>-1) {
			newurl = url.substring(0, url.length-1);
		}
		return newurl
	};
	

    const [producersdb, setProducersdb] = React.useState([]);
	useEffect(() => {
		if (props.chainid) {
			axios.get(plconfig.APIBase + 'apps/api_getproducers.groovy', { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setProducersdb(response.data)
				} else {
				}
			})
			.catch(function(error) {
			});
		}
	}, [props.chainid])

	return (
		<>

        <Typography className={props.classes.maintText}>Täydennä tähän luomutuotteiden kaikki tavarantoimittajat, toimittajan luomutodistuksen voimassaolo sekä viranomaisen tunnus. Nämä tiedot ovat tärkeitä omavalvonnassa ja mm. Oiva-tarkastuksen yhteydessä. Voit hakea tavarantoimittajien luomutodistuksia myös osoitteesta <a href="https://www.ruokavirasto.fi/henkiloasiakkaat/tietoa-elintarvikkeista/luomuruoka/luomuhakupalvelu/" target="_blank">www.ruokavirasto.fi/henkiloasiakkaat/tietoa-elintarvikkeista/luomuruoka/luomuhakupalvelu/</a>.</Typography>

        <Grid container spacing={3}>
            <Grid item xs={4}>

                <Button variant="outlined" color="primary" className={props.classes.addNewButton} onClick={addProducer} disabled={props.readOnly}>Lisää toimittaja</Button>

                <List component="nav" aria-label="Toimittajat" dense={false}>
                    {producers}
                </List>

            </Grid>
            <Grid item xs={8}>

                {(selectedProducer && selectedProducer.PRODUCERIDENTIFIER!='') &&
                <React.Fragment>

                    <Autocomplete
                        id="PRODUCER-combo"
                        options={producersdb.producers.map((option) => option.NAME)}
                        freeSolo
                        value={selectedProducer.PRODUCER} 
						disabled={props.readOnly}
                        onChange={handleProducerNameChange}
                        isOptionEqualToValue={(option, value) => option.NAME === value} 
                        renderInput={(params) => {
                            params.inputProps.autoComplete = 'new-password';
							params.inputProps.readOnly = props.readOnly;
                            return (
                                <TextField 
                                    {...params} 
                                    id="PRODUCER"
                                    label="Luomutuotteiden toimittajan/ostopaikan nimi"
                                    variant="outlined" 
                                    className={props.classes.formInput} 
                                    fullWidth 
                                    InputLabelProps={{shrink: true}} 
                                    value={selectedProducer.PRODUCER} 
                                    onChange={handleProducerNameChange} 
                                    helperText="Voit hakea toimittajia listasta tai kirjoittaa oman toimittajan."
                                />
                            );
                        }}
                    />

                    <DatePicker
                        id="PRODUCERVALIDITY"
                        label="Toimittajan luomutodistuksen voimassaoloaika"
                        inputVariant="outlined"
                        className={props.classes.formInput}
                        value={selectedProducer.PRODUCERVALIDITYDATE}
                        onChange={handleProducerDateChange}
                        animateYearScrolling
						disabled={props.readOnly}
                        format="DD.MM.YYYY"
                        error={!props.isValid && (selectedProducer.PRODUCERVALIDITYDATE=="" || selectedProducer.PRODUCERVALIDITYDATE=="Invalid date" || selectedProducer.PRODUCERVALIDITYDATE==null)}
                    />

                    <FormControl variant="outlined" fullWidth className={props.classes.formInput}>
                        <InputLabel id="PRODUCERIDENTIFIER-select-label" >Viranomaisen tunnus</InputLabel>
                        <Select
                            labelId="PRODUCERIDENTIFIER-select-label"
                            id="PRODUCERIDENTIFIER"
                            name="PRODUCERIDENTIFIER"
                            variant="outlined"
                            value={selectedProducer.PRODUCERIDENTIFIER}
							inputProps={{ readOnly: props.readOnly }}
                            onChange={handleProducerInputChange}
                            >
                            <MenuItem key={0} id="PRODUCERIDENTIFIER" value="">Valitse viranomainen</MenuItem>
                            <MenuItem key={1} id="PRODUCERIDENTIFIER" value={"FI-EKO-101"} >FI-EKO-101 Uudenmaan elinkeino,- liikenne ja ympäristökeskus</MenuItem>
                            <MenuItem key={2} id="PRODUCERIDENTIFIER" value={"FI-EKO-102"} >FI-EKO-102 Varsinais-Suomen elinkeino,- liikenne ja ympäristökeskus</MenuItem>
                            <MenuItem key={3} id="PRODUCERIDENTIFIER" value={"FI-EKO-103"} >FI-EKO-103 Satakunnan elinkeino,- liikenne ja ympäristökeskus</MenuItem>
                            <MenuItem key={4} id="PRODUCERIDENTIFIER" value={"FI-EKO-104"} >FI-EKO-104 Hämeen elinkeino,- liikenne ja ympäristökeskus</MenuItem>
                            <MenuItem key={5} id="PRODUCERIDENTIFIER" value={"FI-EKO-105"} >FI-EKO-105 Pirkanmaan elinkeino,- liikenne ja ympäristökeskus</MenuItem>
                            <MenuItem key={6} id="PRODUCERIDENTIFIER" value={"FI-EKO-106"} >FI-EKO-106 Kaakkois-Suomen elinkeino,- liikenne ja ympäristökeskus</MenuItem>
                            <MenuItem key={7} id="PRODUCERIDENTIFIER" value={"FI-EKO-107"} >FI-EKO-107 Etelä-Savon elinkeino,- liikenne ja ympäristökeskus</MenuItem>
                            <MenuItem key={8} id="PRODUCERIDENTIFIER" value={"FI-EKO-108"} >FI-EKO-108 Pohjois-Savon elinkeino,- liikenne ja ympäristökeskus</MenuItem>
                            <MenuItem key={9} id="PRODUCERIDENTIFIER" value={"FI-EKO-109"} >FI-EKO-109 Pohjois-Karjalan elinkeino,- liikenne ja ympäristökeskus</MenuItem>
                            <MenuItem key={10} id="PRODUCERIDENTIFIER" value={"FI-EKO-110"} >FI-EKO-110 Keski-Suomen elinkeino,- liikenne ja ympäristökeskus</MenuItem>
                            <MenuItem key={11} id="PRODUCERIDENTIFIER" value={"FI-EKO-111"} >FI-EKO-111 Etelä-Pohjanmaan elinkeino,- liikenne ja ympäristökeskus</MenuItem>
                            <MenuItem key={12} id="PRODUCERIDENTIFIER" value={"FI-EKO-112"} >FI-EKO-112 Pohjanmaan elinkeino,- liikenne ja ympäristökeskus</MenuItem>
                            <MenuItem key={13} id="PRODUCERIDENTIFIER" value={"FI-EKO-113"} >FI-EKO-113 Pohjois-Pohjanmaan elinkeino,- liikenne ja ympäristökeskus</MenuItem>
                            <MenuItem key={14} id="PRODUCERIDENTIFIER" value={"FI-EKO-114"} >FI-EKO-114 Kainuun elinkeino,- liikenne ja ympäristökeskus</MenuItem>
                            <MenuItem key={15} id="PRODUCERIDENTIFIER" value={"FI-EKO-115"} >FI-EKO-115 Lapin elinkeino,- liikenne ja ympäristökeskus</MenuItem>
                            <MenuItem key={16} id="PRODUCERIDENTIFIER" value={"FI-EKO-201"} >FI-EKO-201 Ruokavirasto</MenuItem>
                            <MenuItem key={17} id="PRODUCERIDENTIFIER" value={"FI-EKO-301"} >FI-EKO-301 Sosiaali- ja terveysalan lupa- ja valvontavirasto</MenuItem>
                        </Select>
                    </FormControl>


                    <Grid container spacing={3} className={props.classes.bottomGrid2}>
                        <Grid item xs={6}>
                            <FormControl component="fieldset" fullWidth>
                                <FormLabel component="legend">Luomutodistus</FormLabel>
                                {selectedProducer.CERT &&
                                    <a href={fixSlash(plconfig.APIBase)+selectedProducer.CERT} target="_blank">Avaa todistus</a>
                                }
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            {(props.planData.id && props.planData.id!=='new')
                            ? (<FormControl component="fieldset" fullWidth>
                                <FormLabel component="legend">Lataa luomutodistus</FormLabel>
                                <FileInput fileHandler={uploadLuomutodistusFile} btntext="Lataa" readOnly={props.readOnly}/>
                                <FormHelperText>Lataa luomutodistus pdf-muodossa. Koko max 5 mt.</FormHelperText>
                            </FormControl>
                            ) : (
                                <p>Tallenna lisätäksesi oma luomutodistus</p>
                            )}
                        </Grid>
                    </Grid>	

                    <hr/>

                    <FormControl component="fieldset" fullWidth>
                        <FormLabel component="legend">Mistä tuoteryhmistä toimittaja toimittaa luomutuotteita? </FormLabel>
                        <RadioGroup aria-label="suppliergroups" id="suppliergroups" name="usage">
                            {(props.productsdb && props.productsdb.productgroups) &&
                                <React.Fragment>
                                {props.productsdb.productgroups.map((productgroup) =>
                                    <FormControlLabel className={props.classes.checkboxLabel} control={<Checkbox checked={selectedProducer.GROUPS.includes(productgroup.id)} onChange={handleProducerCheckboxChange} id={productgroup.id} name={productgroup.id} />} label={productgroup.NAME} value={productgroup.id} />
                                )}
                                </React.Fragment>
                            }
                        </RadioGroup>
                    </FormControl>
                
                </React.Fragment>
                }

            </Grid>
        </Grid>	

        </>
					
	);
}
