import React, {useEffect} from 'react';
import "moment/locale/fi";
import axios from 'axios';
import plconfig from '../config.js'

import ImportChooser from '../Importer/ImportChooser.js'

import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import InputAdornment from '@material-ui/core/InputAdornment';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FileInput from '../Components/FileInput.js';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Badge from '@material-ui/core/Badge';
import DeleteIcon from '@material-ui/icons/Delete';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import IconButton from '@material-ui/core/IconButton';
import { DatePicker } from "@material-ui/pickers";
import FormHelperText from '@material-ui/core/FormHelperText';
import Autocomplete from '@material-ui/lab/Autocomplete';
import LinkButton from '../Components/LinkButton.js'
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { Alert, AlertTitle } from '@material-ui/lab';
import InputLabel from '@material-ui/core/InputLabel';
import Modal from '@material-ui/core/Modal';
import HelpIcon from '@material-ui/icons/Help';
import CancelIcon from '@material-ui/icons/Cancel';

export default function Products(props) {

	const [selectedProductGroup, setSelectedProductGroup] = React.useState("");
	const [selectedProduct, setSelectedProduct] = React.useState("");

	const [selectedOrganicGradient, setSelectedOrganicGradient] = React.useState({
		USAGE: 'allthetime',
		BIODYNAMIC: false,
		WILDPRODUCT: false,
		RATE: 0,
		NAME: '',
		TUKKUCODE: ''
	});

	const [selectedImportedProducts, setSelectedImportedProducts] = React.useState([]);

	const [sertifiedHelpModalOpen, setSertifiedHelpModalOpen] = React.useState(false);
	const handleModalOpen = () => {
		setSertifiedHelpModalOpen(true);
	};
	const handleModalClose = () => {
		setSertifiedHelpModalOpen(false);
	};

	
	const [totalHelpModalOpen, setTotalHelpModalOpen] = React.useState(false);
	const handleTotalModalOpen = () => {
		setTotalHelpModalOpen(true);
	};
	const handleTotalModalClose = () => {
		setTotalHelpModalOpen(false);
	};



	// ORGANICGRADIENT INPUT HANDLERS
	const handleGradientInputChange = (event) => {

		if (!props.readOnly) {

			//console.log(event.target.id + ' : ' + event.target.value)
			//console.log(selectedOrganicGradient)

			// clean if nousage
			if (event.target.value==='nousage') {
				let og = [...props.planData.ORGANICINGREDIENTS];
				
				let found = false;
				let foundindex = -1;
				let tukkucode = ""

				for (const gradient in og) {
					if (og[gradient].PRODUCTID === selectedOrganicGradient.PRODUCTID) {
						//og[gradient] = selectedWildProductGradient
						og[gradient][event.target.id] = event.target.value
						found = true;
						foundindex = gradient;
						tukkucode = selectedOrganicGradient.TUKKUCODE
					}
				}
				// if found remove from list
				if (found) {
					//console.log(" - found " + tukkucode);
					// remove from array
					og.splice(foundindex, 1)
					setSelectedOrganicGradient({
						USAGE: 'nousage',
						NAME: ''
					});
					// if imported products clear import data 
					if (selectedProductGroup && (selectedProductGroup=='615fcfb6a1c99a6fb14e09c0' || selectedProductGroup=='61652555e4b013438203c9dc')) {
						//console.log(" - remove " + selectedProductGroup);
						axios.post(plconfig.APIBase + 'apps/import_get.groovy?action=clearimporteddata', {
							chainid: props.chainid,
							planid: props.planData.id,
							type: tukkucode
						}, { headers: { "Content-Type": "text/plain" } })
						.then(function (response) {
							if (response.data.success) {
								setSelectedImportedProducts([])
							} else {
							}
						})
						.catch(function(error) {
						});
					}
				}

				props.setPlanData({
					...props.planData,
					["ORGANICINGREDIENTS"]: og
				});

			} else {

				setSelectedOrganicGradient({
					...selectedOrganicGradient,
					[event.target.id]: event.target.value
				});
				// update original gradient also
				let og = [...props.planData.ORGANICINGREDIENTS];
				let found = false;
				if (og) {
					for (const gradient in og) {
						if (og[gradient].PRODUCTID === selectedOrganicGradient.PRODUCTID) {
							//og[gradient] = selectedOrganicGradient
							og[gradient][event.target.id] = event.target.value
							found = true;
						}
					}
					// if not found add clone from selected
					if (!found) {
						const clone = JSON.parse(JSON.stringify(selectedOrganicGradient));
						clone[event.target.id] = event.target.value
						og.push(clone)
					}
				}
				props.setPlanData({
					...props.planData,
					["ORGANICINGREDIENTS"]: og
				});
				//console.log(props.planData)
			}

			props.setPlanSaved(false)

		}
		
	};
	const handleGradientSwitchChange = (event) => {
		if (!props.readOnly) {
			//console.log(event.target.id + ' : ' + event.target.checked)
			setSelectedOrganicGradient({
				...selectedOrganicGradient,
				[event.target.id]: event.target.checked
			});
			// update original gradient also
			let og = [...props.planData.ORGANICINGREDIENTS]
			let found = false;
			if (og) {
				for (const gradient in og) {
					if (og[gradient].PRODUCTID === selectedOrganicGradient.PRODUCTID) {
						//og[gradient] = selectedOrganicGradient
						og[gradient][event.target.id] = event.target.checked
						found = true;
					}
				}
				// if not found add clone from selected
				if (!found) {
					const clone = JSON.parse(JSON.stringify(selectedOrganicGradient));
					clone[event.target.id] = event.target.checked
					og.push(clone)
				}
			}
			props.setPlanData({
				...props.planData,
				["ORGANICINGREDIENTS"]: og
			});
			//console.log(props.planData);
			props.setPlanSaved(false)
		}
	};



	// ORGANICGRADIEND DISPLAY AND SELECT HANDLERS
	const selectProductGroup = (productgroup) => {
		setSelectedProductGroup(productgroup.id);
		//console.log(productgroup.id)
	};
	const selectProduct = (product) => {
		//console.log(product)

		if (selectedProductGroup && (selectedProductGroup=='615fcfb6a1c99a6fb14e09c0' || selectedProductGroup=='61652555e4b013438203c9dc')) {
			axios.post(plconfig.APIBase + 'apps/import_get.groovy?action=importeddata', {
				chainid: props.chainid,
				planid: props.planData.id,
				type: product.TUKKUCODE
			}, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setSelectedImportedProducts(response.data.importedproducts)
				} else {
				}

				let newg = null;
				setSelectedProduct(product.id);
				if (props.planData.ORGANICINGREDIENTS) {
					for (const gradient in props.planData.ORGANICINGREDIENTS) {
						let g = props.planData.ORGANICINGREDIENTS[gradient]
						if (g.PRODUCTID === product.id) {
							newg = g
							newg.NAME = product.NAME
						}
					}
				}
				if (newg) {
					setSelectedOrganicGradient(newg)
				} else {
					setSelectedOrganicGradient({
						USAGE: 'nousage',
						BIODYNAMIC: false,
						WILDPRODUCT: false,
						RATE: 0,
						UNITOFRATE: 'kg/v',
						PRODUCTID: product.id,
						NAME: product.NAME,
						TUKKUCODE: product.TUKKUCODE
					})
				}
			})
			.catch(function(error) {
			});
		} else {
			// clear imported products
			setSelectedImportedProducts([])

			let newg = null;
			setSelectedProduct(product.id);
			if (props.planData.ORGANICINGREDIENTS) {
				for (const gradient in props.planData.ORGANICINGREDIENTS) {
					let g = props.planData.ORGANICINGREDIENTS[gradient]
					if (g.PRODUCTID === product.id) {
						newg = g
						newg.NAME = product.NAME
					}
				}
			}
			if (newg) {
				setSelectedOrganicGradient(newg)
			} else {
				setSelectedOrganicGradient({
					USAGE: 'nousage',
					BIODYNAMIC: false,
					WILDPRODUCT: false,
					RATE: 0,
					UNITOFRATE: 'kg/v',
					PRODUCTID: product.id,
					NAME: product.NAME
				})
			}

		}

		//console.log(selectedOrganicGradient)
	};

	const Calcproductrate = () => {
		var count = 0
		if (props.planData.ORGANICINGREDIENTS) {
			for (const gradient in props.planData.ORGANICINGREDIENTS) {
				if (props.planData.ORGANICINGREDIENTS[gradient].RATE) {
					count += parseInt(props.planData.ORGANICINGREDIENTS[gradient].RATE)
				}
				
			}
		}
		return <React.Fragment>{count}</React.Fragment>
	}
	const Calcyearlyrate = () => {
		var pros = 0
		var organictotal = 0
		var alltotal = props.planData.TOTALUSAGE
		if (props.planData.ORGANICINGREDIENTS) {
			for (const gradient in props.planData.ORGANICINGREDIENTS) {
				if (props.planData.ORGANICINGREDIENTS[gradient].RATE) {
					organictotal += parseInt(props.planData.ORGANICINGREDIENTS[gradient].RATE)
				}
			}
		}
		if (organictotal && alltotal) {
			//pros = (organictotal / alltotal) * 100;
			pros = Math.round((organictotal / alltotal) * 100)
		}
		return <React.Fragment>{pros.toFixed(0)}</React.Fragment>
	}
	const checkproductcount = (productid) => {
		var count = 0
		if (props.planData.ORGANICINGREDIENTS) {
			for (const gradient in props.planData.ORGANICINGREDIENTS) {
				if (props.planData.ORGANICINGREDIENTS[gradient].PRODUCTID === productid && props.planData.ORGANICINGREDIENTS[gradient].USAGE!=='nousage') {
					//count = props.planData.ORGANICINGREDIENTS[gradient].RATE + ' ' +  props.planData.ORGANICINGREDIENTS[gradient].UNITOFRATE
					count++
				}
			}
		}
		return <Badge color="secondary" badgeContent={count} />
	}
	const checkproductgroupcount = (productgroup) => {
		var count = 0
		if (props.planData.ORGANICINGREDIENTS) {
			for (const gradient in props.planData.ORGANICINGREDIENTS) {
				for (const product in props.productsdb.products) {
					if (props.productsdb.products[product].GROUP === productgroup.id) {
						if (props.planData.ORGANICINGREDIENTS[gradient].PRODUCTID === props.productsdb.products[product].id) {
							count++
						}
					}
				}
			}
		}
		return <Badge color="secondary" badgeContent={count} />
	}

	
	const checkProductgroupSelected = (productgroup) => {
		let found = false;
		for (const pg in props.productsdb.productgroups) {
			if (productgroup.id==props.productsdb.productgroups[pg].id) {
				found = true;
			}
		}
		return found;
	}
	
	const clearAllProducts = () => {
		if (confirm("!!! Haluatko varmasti poistaa kaikki tuotetiedot? !!!")) {
			let og = [...props.planData.ORGANICINGREDIENTS];
			og = []
			props.setPlanData({
				...props.planData,
				["ORGANICINGREDIENTS"]: og,
				["TOTALUSAGE"]: 0,
			});
			props.setPlanSaved(false)
		}
	};

	/* IMPORT */
	const handleImport = (addtotal, importer) => {
		//console.log(products)
		//console.log(addtotal)

		let toproduct = null;
		for (const originalgradient in props.productsdb.products) {
			if (importer.type===props.productsdb.products[originalgradient].TUKKUCODE) {
				toproduct = props.productsdb.products[originalgradient]
			}
		}

		let og = [...props.planData.ORGANICINGREDIENTS];
		let tog = [...props.planData.TOTALUSAGE]
		if (importer.totalweight && addtotal) {
			//console.log(importer.totalweight)
			let numbertog = parseFloat(props.planData.TOTALUSAGE)
			let numberimporter = parseFloat(importer.totalweight)
			let newnumbertotal = numbertog + numberimporter
			tog = newnumbertotal.toFixed(2)
			//console.log(numbertog)
			//console.log(numberimporter)
			//console.log(newnumbertotal)
			//console.log(tog)
		}

		let found = false;
		for (const gradient in og) {
			if (importer.type===og[gradient].TUKKUCODE) {
				let numbertog2 = parseFloat(og[gradient]['RATE'])
				let numberimporter2 = parseFloat(importer.ekoweight)
				let newnumbertotal2 = numbertog2 + numberimporter2
				og[gradient]['RATE'] = newnumbertotal2.toFixed(2)
				og[gradient]['USAGE'] = "allthetime"
				og[gradient]['BIODYNAMIC'] = false
				og[gradient]['WILDPRODUCT'] = false
				found = true;
			}
		}
		// if not found add clone from selected
		if (!found) {
			for (const originalgradient in props.productsdb.products) {
				if (importer.type===props.productsdb.products[originalgradient].TUKKUCODE) {
					const clone = JSON.parse(JSON.stringify(props.productsdb.products[originalgradient]));
					let numberimporter3 = parseFloat(importer.ekoweight)
					clone['RATE'] = numberimporter3.toFixed(2)
					clone['USAGE'] = "allthetime"
					clone['BIODYNAMIC'] = false
					clone['WILDPRODUCT'] = false
					clone['PRODUCTID'] = props.productsdb.products[originalgradient].id

					delete clone['id']
					delete clone['GROUP']
					delete clone['ORDERNRO']

					og.push(clone)
				}
			}
		}

		if (addtotal) {
			props.setPlanData({
				...props.planData,
				["TOTALUSAGE"]: ""+tog,
				["ORGANICINGREDIENTS"]: og
			});
		} else {
			props.setPlanData({
				...props.planData,
				["ORGANICINGREDIENTS"]: og
			});
		}

		props.setPlanSaved(false)

		setSelectedOrganicGradient({
			USAGE: 'nousage',
			NAME: ''
		});
	};


	return (
		<>

			<Grid container spacing={3} className={props.classes.bottomGrid2}>
			<Grid item xs={8}>
				<Typography className={props.classes.maintText}>Tähän kirjataan kaikkien käytössä olevien elintarvikkeiden (myös juomat) käyttömäärä kg/vuodessa. Käyttömäärät saat tavarantoimittajien ostolistauksista. Jos lisäät suunnitelmaan tietoja manuaalisesti, niin muista lisätä luomutuotteiden kilomäärät myös tähän kohtaan. Elintarvikkeiden kokonaismäärä on tärkeä tieto luomuosuuden laskemisessa.</Typography>
			</Grid>
			<Grid item xs={4} className={props.classes.bottomGridRight}>
				<Grid container spacing={0}>
					<Grid item xs={6}>
						<ImportChooser chainid={props.chainid} planid={props.planData.id} handleImport={handleImport} readOnly={props.readOnly} />
					</Grid>
					<Grid item xs={6}>
						<Button variant="outlined" className={props.classes.deleteallbutton} onClick={clearAllProducts} disabled={props.readOnly}>Tyhjennä tuotetiedot</Button> 
					</Grid>
				</Grid>	
			</Grid>
		</Grid>	

		<Grid container spacing={0}>
			<Grid item xs={6} className={props.classes.infogrid}>
				<Typography className={props.classes.extraInfoText2}>Elintarvikkeiden kokonaiskäyttömäärä <IconButton className={props.classes.HelpIconButton} aria-label="Avaa lisätietoikkuna" onClick={handleTotalModalOpen}><HelpIcon /></IconButton></Typography>
				<TextField
					id="TOTALUSAGE"
					label="Elintarvikkeiden kokonaiskäyttömäärä *"
					variant="outlined"
					fullWidth
					className={props.classes.formInput}
					type="number"
					value={props.planData.TOTALUSAGE}
					InputProps={{
						endAdornment: <InputAdornment position="end">kg/vuosi</InputAdornment>,
						readOnly: props.readOnly
					}}
					onChange={props.handleTextInputChange}
					InputLabelProps={{
						shrink: true,
					}}
					error={!props.isValid && (props.planData.TOTALUSAGE=="" || props.planData.TOTALUSAGE==null)}
				/>
			</Grid>
			<Grid item xs={6} className={props.classes.infogridright}>
				<Box spacing={3}>
					<Typography>Merkittäviä luomuelintarvikkeita yhteensä: <strong><Calcproductrate/> kg/v</strong></Typography>
					{props.planData.TOTALUSAGE &&
						<Typography className={props.classes.maintText}>Elintarvikkeiden määrästä luomua: <strong><Calcyearlyrate/> %</strong></Typography>
					}
				</Box>
			</Grid>
		</Grid>

		<Modal
			open={totalHelpModalOpen}
			onClose={handleTotalModalClose}
			aria-labelledby="Lisäinfo kokonaispainolle"
			aria-describedby="Lisäinfo kokonaispainolle"
		>
			<div className={props.classes.modalPaper}>
				<IconButton className={props.classes.modalCloseButton} onClick={handleTotalModalClose}><CancelIcon /></IconButton>
				<p>Elintarvikkeiden kokonaiskäyttömäärä lasketaan toimipaikkojen volyymin mukaan ja mikäli tehdään uutta suunnitelmaa se esitäytetään alla olevaan kenttään. </p>
				<p>Kaikkien käytössä olevien (ei luomu + luomu) elintarvikkeiden käyttömäärä kg/vuodessa. Mikäli kaikkien käytössä olevien elintarvikkeiden käyttömäärä/vuosi on vaikea saada esim. organisaation omasta ostoraportista, niin kokonaismäärän voi suhteuttaa annosmäärien mukaan. Suhteutuksen voi tehdä esim.: elintarvikkeiden käyttömäärät/annos -{'>'} annosta/päivä -{'>'} annosta/vuosi = kokonaiskäyttömäärä/vuosi</p>
			</div>
		</Modal>


		<Grid container spacing={2}>
			<Grid item xs={3} className={props.classes.productColumn1}>
				<Typography className={props.classes.productHeader}>Tuoteryhmät</Typography>
				<List component="nav" aria-label="Luomutuoteryhmät" dense={false}>
					{(props.productsdb && props.productsdb.productgroups) &&
						<React.Fragment>
						{props.productsdb.productgroups.map((productgroup) =>
							<ListItem key={productgroup.id} button selected={selectedProductGroup===productgroup.id} onClick={() => selectProductGroup(productgroup)}><ListItemText primary={productgroup.NAME} />
								{checkproductgroupcount(productgroup)}
							</ListItem>
						)}
						</React.Fragment>
					}
				</List>	
			</Grid>
			<Grid item xs={3} className={props.classes.productColumn2}>
				<Typography className={props.classes.productHeader}>Tuotteet</Typography>
				<List component="nav" aria-label="Luomutuotteet" dense={false}>
					{(props.productsdb && props.productsdb.products) &&
						<React.Fragment>
						{props.productsdb.products.map((product) =>
							<React.Fragment>
								{product.GROUP === selectedProductGroup &&
									<ListItem button selected={selectedProduct===product.id} onClick={() => selectProduct(product)}><ListItemText primary={props.capitalizeFirstLetter(product.NAME)} />
										{checkproductcount(product.id)}
									</ListItem>
								}
							</React.Fragment>
						)}
						</React.Fragment>
					}
				</List>	
			</Grid>
			<Grid item xs={6} className={props.classes.productColumn3}>

				{(selectedOrganicGradient && selectedOrganicGradient.NAME!=='') &&

				<div className={props.classes.productForm}>

					<Typography className={props.classes.productHeader2}>Tuotteen "{selectedOrganicGradient.NAME}" käyttötiedot</Typography>

					<TextField
						id="RATE"
						label="Käyttömäärä"
						variant="outlined"
						className={props.classes.formInput}
						type="number"
						fullWidth
						value={selectedOrganicGradient.RATE}
						InputProps={{
							endAdornment: <InputAdornment position="end">{selectedOrganicGradient.UNITOFRATE}</InputAdornment>,
							readOnly: props.readOnly
						}}
						helperText="Kuinka paljon tuotetta käytetään vuodessa"
						onChange={handleGradientInputChange}
						InputLabelProps={{
							shrink: true,
						}}
					/>

					{(selectedProductGroup && (selectedProductGroup=='615fcfb6a1c99a6fb14e09c0' || selectedProductGroup=='61652555e4b013438203c9dc')) ?
						<React.Fragment>
							<FormControl component="fieldset" fullWidth>
								<RadioGroup aria-label="usage" id="USAGE" name="USAGE" value={selectedOrganicGradient.USAGE} onChange={handleGradientInputChange}>
									<FormControlLabel value="nousage" control={<Radio id="USAGE" checked={selectedOrganicGradient.USAGE === 'nousage'} value="nousage" />} label="Tuotteet ei käytössä (poistetaan listauksesta)" />
									<FormControlLabel value="allthetime" control={<Radio id="USAGE" checked={selectedOrganicGradient.USAGE === 'allthetime'} value="allthetime"/>} label="Tuotteet käytössä" />
								</RadioGroup>
							</FormControl>
						</React.Fragment>
					:
						<React.Fragment>
							<FormControl component="fieldset" fullWidth>
								<FormLabel component="legend">Tuotetta käytetään</FormLabel>
								<RadioGroup aria-label="usage" id="USAGE" name="USAGE" value={selectedOrganicGradient.USAGE} onChange={handleGradientInputChange}>
									<FormControlLabel value="nousage" control={<Radio id="USAGE" checked={selectedOrganicGradient.USAGE === 'nousage'} value="nousage" />} label="Tuote ei käytössä (poistetaan listauksesta)" />
									<FormControlLabel value="allthetime" control={<Radio id="USAGE" checked={selectedOrganicGradient.USAGE === 'allthetime'} value="allthetime"/>} label="Käytetään jatkuvasti" />
									<FormControlLabel value="twiceweek" control={<Radio id="USAGE" checked={selectedOrganicGradient.USAGE === 'twotimes'} value="twotimes"/>} label="Kaksi kertaa viikossa" />
									<FormControlLabel value="other" control={<Radio id="USAGE" checked={selectedOrganicGradient.USAGE === 'rarely'} value="rarely" />} label="Harvemmin" />
								</RadioGroup>
							</FormControl>

							<FormControl component="fieldset" fullWidth>
								<FormLabel component="legend">Tuote on <IconButton className={props.classes.HelpIconButton} aria-label="Avaa lisätietoikkuna" onClick={handleModalOpen}><HelpIcon /></IconButton></FormLabel>
								<FormControlLabel value="biodynamic" control={<Checkbox id="BIODYNAMIC" checked={selectedOrganicGradient.BIODYNAMIC} onChange={handleGradientSwitchChange}/>} label="Biodynaaminen" />
								<FormControlLabel value="wild" control={<Checkbox id="WILDPRODUCT" checked={selectedOrganicGradient.WILDPRODUCT} onChange={handleGradientSwitchChange}/>} label="Luomusertifioitu Villi/keruutuote"></FormControlLabel>
							</FormControl>
						</React.Fragment>
					}


					{(selectedImportedProducts && selectedImportedProducts.length>0 ) &&
						<React.Fragment>
							{selectedImportedProducts.map((selectedImportedProduct) =>
								<pre>{selectedImportedProduct.productdata}</pre>
							)}
						</React.Fragment>
					}

				</div>

				}

				<Modal
					open={sertifiedHelpModalOpen}
					onClose={handleModalClose}
					aria-labelledby="Lisäinfo tuotteille"
					aria-describedby="Lisäinfo tuotteille"
				>
					
					<div className={props.classes.modalPaper}>
						<IconButton className={props.classes.modalCloseButton} onClick={handleModalClose}><CancelIcon /></IconButton>
						<p>Metsästä ja järvestä saadut luonnonantimet eivät automaattisesti ole luomua. Keruutuotteet, kuten metsämarjat, sienet, luonnonyrtit ja muut erikoiskeruutuotteet ovat luomutuotteita ainoastaan silloin, kun ne on kerätty luomusertifioiduilta keruualueilta. Villikalaa, riistaa ja poroja, ei voi myydä tai sertifioida luomuksi. Lisätietoa luomukeruutuotteisiin liittyvästä lainsäädännöstä löydät osoitteesta <Link href="https://www.ruokavirasto.fi" target="_blank">www.ruokavirasto.fi</Link></p>
					</div>
				</Modal>

			</Grid>
		</Grid>	
        
        </>
					
	);
}
