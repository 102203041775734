import React from 'react'
import { withRouter } from 'react-router'
import Button from '@material-ui/core/Button';

const FileInput = (props) => {

	const fileInput = React.useRef();
	const [selectedFile, setSelectedFile] = React.useState('');
	const handleFileInputChange = (event) => {
		setSelectedFile(event.target.files[0])
		props.fileHandler(event.target.files[0])
	};

	return (
		 <React.Fragment>
			<Button variant="contained" color="primary" onClick={()=>fileInput.current.click()} disabled={props.readOnly}>{props.btntext}</Button>
			<input ref={fileInput} type="file" style={{ display: 'none' }} onChange={handleFileInputChange} />
		</React.Fragment>
	)
}

export default withRouter(FileInput)