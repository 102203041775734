import React from 'react';
import "moment/locale/fi";

import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import Checkbox from '@material-ui/core/Checkbox';


export default function CriticalPoints(props) {


    // CRITICAL CHECKBOX INPUT CHANGER
	const handleCriticalCheckboxChange = (event) => {

        if (!props.readOnly) {
            //console.log(event.target.id + ' : ' + event.target.checked)

            let currentcritical = props.planData[event.target.name];
            if (event.target.checked) {
                if (currentcritical.includes(event.target.id)) {
                } else {
                    currentcritical.push(event.target.id);
                }
            } else {
                if (currentcritical.includes(event.target.id)) {
                    let found = -1;
                    if (currentcritical) {
                        for (const item in currentcritical) {
                            if (event.target.id==currentcritical[item]) {
                                found = item;
                            }
                        }
                    }
                    if (found>-1) {
                        currentcritical.splice(found, 1);
                    }
                }
            }
            props.setPlanData({
                ...props.planData,
                [event.target.name]: currentcritical
            });

            //console.log(props.planData[event.target.name]);
            props.setPlanSaved(false)

        }

	};


	return (
		<>

        <Typography className={props.classes.maintText}>Täytä tähän huolellisesti luomun käyttöön ja luomusta viestimiseen liittyvät asiat. Huolellisesti täytettynä tätä lomaketta voi käyttää apuna OIVA-tarkastuksessa luomun käytön todenteena.</Typography>
						
        <div className={props.classes.productForm}>

            <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" error={!props.isValid && props.planData.CRITICAL1.length==0}>Miten varmistatte, että luomutuotteet eivät sekoitu tavanomaisiin (ei luomu) tuotteisiin ja että henkilökunta on tietoinen luomutuotteiden käytöstä? *</FormLabel>
                <RadioGroup aria-label="critical1" id="critical1" name="usage">
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical1-1" control={<Checkbox checked={props.planData.CRITICAL1.includes("critical1-1")} onChange={handleCriticalCheckboxChange} id="critical1-1" name="CRITICAL1" />} label="luomutuotteille on oma merkattu paikka varastossa/hyllyssä" />
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical1-2" control={<Checkbox checked={props.planData.CRITICAL1.includes("critical1-2")} onChange={handleCriticalCheckboxChange} id="critical1-2" name="CRITICAL1" />} label="luomutuotteet ovat omissa pakkauksissaan" />
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical1-3" control={<Checkbox checked={props.planData.CRITICAL1.includes("critical1-3")} onChange={handleCriticalCheckboxChange} id="critical1-3" name="CRITICAL1" />} label="irtoluomutuotteet ovat omissa merkatuissa laatikoissaan" />
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical1-4" control={<Checkbox checked={props.planData.CRITICAL1.includes("critical1-4")} onChange={handleCriticalCheckboxChange} id="critical1-4" name="CRITICAL1" />} label="luomutuotteiksi valittujen rinnalla ei käytetä vastaavia tavanomaisia tuotteita" />
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical1-5" control={<Checkbox checked={props.planData.CRITICAL1.includes("critical1-5")} onChange={handleCriticalCheckboxChange} id="critical1-5" name="CRITICAL1" />} label="henkilökunnalla on nähtävillä listaus käytössä olevista luomutuotteista" />
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical1-6" control={<Checkbox checked={props.planData.CRITICAL1.includes("critical1-6")} onChange={handleCriticalCheckboxChange} id="critical1-6" name="CRITICAL1" />} label="reseptiikkaan on kiinnitetty luomuna käytettävät tuotteet" />
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical1-7" control={<Checkbox checked={props.planData.CRITICAL1.includes("critical1-7")} onChange={handleCriticalCheckboxChange} id="critical1-7" name="CRITICAL1" />} label="muuta mitä?" />
                </RadioGroup>
            </FormControl>

            <TextField
                id="CRITICAL1OTHER"
                label="Tarkenna tähän luomun käyttöön liittyviä toimintoja esim. käytöstä, vastaanotosta ja viestinnästä"
                variant="outlined"
                className={props.classes.formInput}
                type="text"
                value={props.planData.CRITICAL1OTHER}
                fullWidth
                multiline
                rows="3"
                onChange={props.handleTextInputChange}
                inputProps={{ readOnly: props.readOnly }}
                InputLabelProps={{
                    shrink: true,
                }}
            />	

            <hr/>

            <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" error={!props.isValid && props.planData.CRITICAL2.length==0}>Miten vastaanottotarkastuksen yhteydessä tarkistetaan, että tuotteet ovat luomua? </FormLabel>
                <RadioGroup aria-label="critical2" id="critical2" name="usage">
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical2-1" control={<Checkbox checked={props.planData.CRITICAL2.includes("critical2-1")} onChange={handleCriticalCheckboxChange} id="critical2-1" name="CRITICAL2" />} label="tarkistetaan lähetyslistojen ja saapuneen kuorman vastaavuus luomun osalta" />
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical2-2" control={<Checkbox checked={props.planData.CRITICAL2.includes("critical2-2")} onChange={handleCriticalCheckboxChange} id="critical2-2" name="CRITICAL2" />} label="tarkistetaan, että luomutuotteista löytyy viralliset luomumerkinnät" />
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical2-3" control={<Checkbox checked={props.planData.CRITICAL2.includes("critical2-3")} onChange={handleCriticalCheckboxChange} id="critical2-3" name="CRITICAL2" />} label="pyydetään tavarantoimittajilta luomutodistukset, jotka säilytetään omavalvontakansiossa" />
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical2-4" control={<Checkbox checked={props.planData.CRITICAL2.includes("critical2-4")} onChange={handleCriticalCheckboxChange} id="critical2-4" name="CRITICAL2" />} label="muuta mitä?" />
                </RadioGroup>
            </FormControl>


            <TextField
                id="CRITICAL2OTHER"
                label="Tarkenna tähän luomun käyttöön liittyviä toimintoja esim. käytöstä, vastaanotosta ja viestinnästä"
                variant="outlined"
                className={props.classes.formInput}
                type="text"
                value={props.planData.CRITICAL2OTHER}
                fullWidth
                multiline
                rows="3"
                onChange={props.handleTextInputChange}
                inputProps={{ readOnly: props.readOnly }}
                InputLabelProps={{
                    shrink: true,
                }}
            />	

            <hr/>

            <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" error={!props.isValid && props.planData.CRITICAL3.length==0}>Luomutuotteiden käytön läpinäkyvyys? </FormLabel>
                <RadioGroup aria-label="critical3" id="critical3" name="usage">
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical3-1" control={<Checkbox checked={props.planData.CRITICAL3.includes("critical3-1")} onChange={handleCriticalCheckboxChange} id="critical3-1" name="CRITICAL3" />} label="käytössä olevat luomutuotteet ovat näkyvillä asiakastiloissa esim. listattuna" />
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical3-2" control={<Checkbox checked={props.planData.CRITICAL3.includes("critical3-2")} onChange={handleCriticalCheckboxChange} id="critical3-2" name="CRITICAL3" />} label="ruokalistalla luomutuotteet merkattuna" />
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical3-3" control={<Checkbox checked={props.planData.CRITICAL3.includes("critical3-3")} onChange={handleCriticalCheckboxChange} id="critical3-3" name="CRITICAL3" />} label="henkilökunnan kanssa käydään käytössä olevat luomutuotteet läpi säännöllisesti" />
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical3-4" control={<Checkbox checked={props.planData.CRITICAL3.includes("critical3-4")} onChange={handleCriticalCheckboxChange} id="critical3-4" name="CRITICAL3" />} label="Luomua ravintola -tunnus on näkyvillä asiakastiloissa" />
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical3-5" control={<Checkbox checked={props.planData.CRITICAL3.includes("critical3-5")} onChange={handleCriticalCheckboxChange} id="critical3-5" name="CRITICAL3" />} label="muuta mitä?" />
                </RadioGroup>
            </FormControl>

            <TextField
                id="CRITICAL3OTHER"
                label="Tarkenna tähän luomun käyttöön liittyviä toimintoja esim. käytöstä, vastaanotosta ja viestinnästä"
                variant="outlined"
                className={props.classes.formInput}
                type="text"
                value={props.planData.CRITICAL3OTHER}
                fullWidth
                multiline
                rows="3"
                onChange={props.handleTextInputChange}
                inputProps={{ readOnly: props.readOnly }}
                InputLabelProps={{
                    shrink: true,
                }}
            />	

            <hr/>

            <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" error={!props.isValid && props.planData.CRITICAL4.length==0}>Miten varmistatte, että ruuan valmistuksessa on käytetty niiden hankintaa vastaava määrä? varastokirjanpito, omavalvontakirjanpito tms </FormLabel>
                <RadioGroup aria-label="critical4" id="critical4" name="usage">
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical4-1" control={<Checkbox checked={props.planData.CRITICAL4.includes("critical4-1")} onChange={handleCriticalCheckboxChange} id="critical4-1" name="CRITICAL4" />} label="luomutuotteiden tilaus tehdään voimassa olevan ruokalistan mukaan " />
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical4-2" control={<Checkbox checked={props.planData.CRITICAL4.includes("critical4-2")} onChange={handleCriticalCheckboxChange} id="critical4-2" name="CRITICAL4" />} label="tilatut luomutuotteet käytetään mahdollisimman nopeasti ja 'first in, first out' -periaatteella" />
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical4-3" control={<Checkbox checked={props.planData.CRITICAL4.includes("critical4-3")} onChange={handleCriticalCheckboxChange} id="critical4-3" name="CRITICAL4" />} label="lähetyslistat säilytetään omavalvontakansiossa" />
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical4-4" control={<Checkbox checked={props.planData.CRITICAL4.includes("critical4-4")} onChange={handleCriticalCheckboxChange} id="critical4-4" name="CRITICAL4" />} label="tuotannonohjausjärjestelmästä saadaan selville luomutuotteiden käyttömäärät vuositasolla" />
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical4-5" control={<Checkbox checked={props.planData.CRITICAL4.includes("critical4-5")} onChange={handleCriticalCheckboxChange} id="critical4-5" name="CRITICAL4" />} label="muuta mitä?" />
                </RadioGroup>
            </FormControl>

            <TextField
                id="CRITICAL4OTHER"
                label="Tarkenna tähän luomun käyttöön liittyviä toimintoja esim. käytöstä, vastaanotosta ja viestinnästä"
                variant="outlined"
                className={props.classes.formInput}
                type="text"
                value={props.planData.CRITICAL4OTHER}
                fullWidth
                multiline
                rows="3"
                onChange={props.handleTextInputChange}
                inputProps={{ readOnly: props.readOnly }}
                InputLabelProps={{
                    shrink: true,
                }}
            />	

            <hr/>

            <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" error={!props.isValid && props.planData.CRITICAL5.length==0}>Miten henkilökuntaa on ohjeistettu/koulutettu luomutuotteiden käyttöön liittyvistä asioista?(luomuviestintä, luomun erot tavanomaiseen (ei luomuun), luomun sekoittuminen tavanomaiseen (ei luomu) jne. </FormLabel>
                <RadioGroup aria-label="critical5" id="critical5" name="usage">
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical5-1" control={<Checkbox checked={props.planData.CRITICAL5.includes("critical5-1")} onChange={handleCriticalCheckboxChange} id="critical5-1" name="CRITICAL5" />} label="henkilökunnan kanssa käydään säännöllisesti läpi käytössä olevat raaka-aineet ja luomutuotteet" />
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical5-2" control={<Checkbox checked={props.planData.CRITICAL5.includes("critical5-2")} onChange={handleCriticalCheckboxChange} id="critical5-2" name="CRITICAL5" />} label="henkilökunnalle on kerrottu organisaation vastuullisuusstrategiasta sekä siihen liittyvästä luomutuotteiden käytöstä" />
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical5-3" control={<Checkbox checked={props.planData.CRITICAL5.includes("critical5-3")} onChange={handleCriticalCheckboxChange} id="critical5-3" name="CRITICAL5" />} label="henkilökunnalle on kerrottu Portaat luomuun -ohjelmasta ja sen toiminnasta " />
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical5-4" control={<Checkbox checked={props.planData.CRITICAL5.includes("critical5-4")} onChange={handleCriticalCheckboxChange} id="critical5-4" name="CRITICAL5" />} label="henkilökunnan kanssa on käyty läpi luomuun ja luomuviestintään liittyvät perusasiat" />
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical5-5" control={<Checkbox checked={props.planData.CRITICAL5.includes("critical5-5")} onChange={handleCriticalCheckboxChange} id="critical5-5" name="CRITICAL5" />} label="muuta mitä?" />
                </RadioGroup>
            </FormControl>

            <TextField
                id="CRITICAL5OTHER"
                label="Tarkenna tähän luomun käyttöön liittyviä toimintoja esim. käytöstä, vastaanotosta ja viestinnästä"
                variant="outlined"
                className={props.classes.formInput}
                type="text"
                value={props.planData.CRITICAL5OTHER}
                fullWidth
                multiline
                rows="3"
                onChange={props.handleTextInputChange}
                inputProps={{ readOnly: props.readOnly }}
                InputLabelProps={{
                    shrink: true,
                }}
            />	

            <hr/>

            <FormControl component="fieldset" fullWidth>
                <FormLabel component="legend" error={!props.isValid && props.planData.CRITICAL6.length==0}>Miten luomutuotteiden käytöstä kerrotaan asiakkaille?  </FormLabel>
                <RadioGroup aria-label="critical6" id="critical6" name="usage">
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical6-1" control={<Checkbox checked={props.planData.CRITICAL6.includes("critical6-1")} onChange={handleCriticalCheckboxChange} id="critical6-1" name="CRITICAL6" />} label="luomutuotteet on merkattu ruokalistalle" />
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical6-2" control={<Checkbox checked={props.planData.CRITICAL6.includes("critical6-2")} onChange={handleCriticalCheckboxChange} id="critical6-2" name="CRITICAL6" />} label="käytössä olevien luomutuotteiden listaus on näkyvillä asiakkaille ja se päivitetään säännöllisesti " />
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical6-3" control={<Checkbox checked={props.planData.CRITICAL6.includes("critical6-3")} onChange={handleCriticalCheckboxChange} id="critical6-3" name="CRITICAL6" />} label="asiakastiloissa, ruokalistoilla ja nettisivustolla on näkyvillä Luomua ravintola -tunnus" />
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical6-4" control={<Checkbox checked={props.planData.CRITICAL6.includes("critical6-4")} onChange={handleCriticalCheckboxChange} id="critical6-4" name="CRITICAL6" />} label="luomutuotteiden käytöstä kerrotaan ravintolan/ruokapalvelun nettisivustolla sekä muussa sähköisessä mediassa" />
                    <FormControlLabel className={props.classes.checkboxLabel} value="critical6-5" control={<Checkbox checked={props.planData.CRITICAL6.includes("critical6-5")} onChange={handleCriticalCheckboxChange} id="critical6-5" name="CRITICAL6" />} label="muuta mitä?" />
                </RadioGroup>
            </FormControl>

            <TextField
                id="CRITICAL6OTHER"
                label="Tarkenna tähän luomun käyttöön liittyviä toimintoja esim. käytöstä, vastaanotosta ja viestinnästä"
                variant="outlined"
                className={props.classes.formInput}
                type="text"
                value={props.planData.CRITICAL6OTHER}
                fullWidth
                multiline
                rows="3"
                onChange={props.handleTextInputChange}
                inputProps={{ readOnly: props.readOnly }}
                InputLabelProps={{
                    shrink: true,
                }}
            />	

            <hr/>

        </div>


        
        </>
					
	);
}
