import React, {useEffect} from 'react';
import { useAuth } from './Auth/Auth'
import axios from 'axios';
import moment from "moment";
import plconfig from './config.js'
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import {Line} from 'react-chartjs-2';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import Typography from '@material-ui/core/Typography';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';

import Modal from '@material-ui/core/Modal';
import IconButton from '@material-ui/core/IconButton';
import HelpIcon from '@material-ui/icons/Help';
import LinkButton from './Components/LinkButton'
import { Block } from '@material-ui/icons';
import CancelIcon from '@material-ui/icons/Cancel';

const useStyles = makeStyles((theme) => ({
	container : {
		marginTop: 70
	},
	topGrid: {
		marginBottom: theme.spacing(2)
	},
	paperTopGrid: {
		padding: theme.spacing(3),
		position: 'relative'
	},
	paper: {
		position: 'relative',
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '600',
		padding: theme.spacing(3),
		marginBottom: theme.spacing(3)
	},
	paperbg: {
		fontFamily: "'Work Sans', sans-serif",
		height: '100%',
		padding: theme.spacing(3),
		textAlign: 'center',
		color: '#fff',
		//backgroundImage: 'url(bg-dashboard.jpg)',
		backgroundRepeat: 'no-repeat',
		backgroundSize: 'cover',
		backgroundColor: theme.palette.primary.main,
	},
	banner: {
		position: 'relative',
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '600',
		padding: theme.spacing(3),
		marginBottom: 0,
		minHeight: 80,
		textAlign: 'center',
		backgroundColor: 'transparent',
	},
	banner2: {
		position: 'relative',
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '600',
		padding: theme.spacing(3),
		marginBottom: theme.spacing(3),
		minHeight: 220,
		textAlign: 'center',
		backgroundColor: 'transparent',
	},
	bannerimage: {
		maxWidth: '100%',
		height: 'auto'
	},
	bannertext: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '700',
		color: "#38b559",
		padding: '40px 20px',
		textAlign: 'center',
		backgroundColor: 'transparent',
		border: '3px solid #38b559',
	},
	wildmarkimage: {
		maxWidth: '60px',
		height: 'auto',
		marginRight: 10,
	},
	darklogo: {
		marginTop: 20,
		marginBottom: 20
	},
	pointNumberTitle: {
		fontSize: '14pt',
		marginTop: 20,
		marginBottom: 0
	},
	pointNumber: {
		fontFamily: "'Work Sans', sans-serif",
		fontSize: '96pt',
		fontWeight: '700',
		marginBottom: 40
	},
	doneIcon: {
		fontSize: '30pt',
	},
	doneTxt: {
		fontSize: '12pt',
	},
	printBtn: {
		color: "#fff",
		marginTop: 16,
	},
	PaperHeader: {
		marginBottom: 16,
		marginLeft: theme.spacing(3)
	},
	PaperTitle: {
		fontFamily: "'Work Sans', sans-serif",
		fontWeight: '500',
	},
	muted: {
		fontSize: '0.8em',
		fontWeight: '300',
		color: theme.palette.text.muted
	},
	chartNumber: {
		textAlign: 'center',
		fontSize: '30pt',
		fontWeight: '700',
		color: "#38b559"
	},
	chartNumberPad: {
		textAlign: 'center',
		fontSize: '30pt',
		fontWeight: '700',
		paddingLeft: 15,
		color: "#38b559"
	},
	chartLabel: {
		textAlign: 'center',
		fontSize: '0.8em',
		color: theme.palette.text.muted,
		marginBottom: 5
	},
	chartMini: {
		fontSize: '0.4em',
		fontWeight: '300',
		paddingLeft: 3,
		color: theme.palette.text.secondary
	},
	newsLogo: {
		maxWidth: "100%"
	},
	modalPaper: {
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
		maxWidth: 800,
		height: 350,
		backgroundColor: theme.palette.background.paper,
		border: '4px solid #c3c3c3',
		boxShadow: theme.shadows[5],
		padding: theme.spacing(4),
	},
	modalCloseButton: {
		position: 'absolute',
		top: 5,
		right: 5,
	},
	HelpIconButton: {
		position: 'absolute',
		top: 10,
		right: 24
	},
	deletePlanBtn: {
		color: "#f44336",
		borderColor: "#f44336",
		marginLeft: theme.spacing(3)
	},
	materialLink: {
		display: 'block',
		paddingBottom: theme.spacing(1)
	}
}));

const usagedataoptions = {
	scales: {
		yAxes: [{
			ticks: {
				beginAtZero: true,
				suggestedMax: 100
			}
		}]
	}
}

function getLastFiveYears() {
	let lastfiveyears = [];
	for (let i = 0; i <= 4; i++) {
		lastfiveyears.push( moment().add(-i, 'years').format('yyyy') )
	}
	lastfiveyears = lastfiveyears.reverse();
	return lastfiveyears;
}



let baseusagedata = {
	labels: getLastFiveYears(),
	datasets: [{
		label: 'Toteutunut',
		fill: true,
		lineTension: 0,
		backgroundColor: 'rgba(56,181,89,0.4)',
		borderColor: 'rgba(56,181,89,1)',
		pointBorderColor: 'rgba(56,181,89,1)',
		pointBackgroundColor: '#fff',
		pointBorderWidth: 1,
		pointHoverRadius: 5,
		pointHoverBackgroundColor: 'rgba(56,181,89,1)',
		pointHoverBorderColor: 'rgba(220,220,220,1)',
		pointHoverBorderWidth: 2,
		pointRadius: 4,
		pointHitRadius: 10,
		data: [0, 0, 0, 0, 0]
	  },{
		label: 'Suositus',
		fill: false,
		lineTension: 0,
		backgroundColor: 'rgba(125,125,125,0.4)',
		borderColor: 'rgba(125,125,125,1)',
		pointBorderColor: 'rgba(125,125,125,1)',
		pointBackgroundColor: '#fff',
		pointBorderWidth: 1,
		pointHoverRadius: 5,
		pointHoverBackgroundColor: 'rgba(125,125,125,1)',
		pointHoverBorderColor: 'rgba(220,220,220,1)',
		pointHoverBorderWidth: 2,
		pointRadius: 4,
		pointHitRadius: 10,
		data: [20, 20, 25, 25, 25]
	  },{
		label: 'Ohjelman keskiarvo',
		fill: true,
		lineTension: 0,
		backgroundColor: 'rgba(29,145,189,0.4)',
		borderColor: 'rgba(29,145,189,1)',
		pointBorderColor: 'rgba(29,145,189,1)',
		pointBackgroundColor: '#fff',
		pointBorderWidth: 1,
		pointHoverRadius: 5,
		pointHoverBackgroundColor: 'rgba(29,145,189,1)',
		pointHoverBorderColor: 'rgba(220,220,220,1)',
		pointHoverBorderWidth: 2,
		pointRadius: 4,
		pointHitRadius: 10,
		data: [24, 29, 21, 21, 21]
	  }
	]
};


function capitalizeFirstLetter(string) {
	if (string) {
		return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
	} else {
		return ""
	}
}

function updateUsageData(newdata) {
	//console.log(newdata)

	var reversedlastfiveyears = [...getLastFiveYears()];
	//console.log(reversedlastfiveyears)

	var returnData = [];
	for (let y in reversedlastfiveyears) {
		let newvalue = 0;
		for (let i in newdata) {
			let plan = newdata[i]
			//console.log("Trying to match " + plan.agreementdate + " to " + lastfiveyears[y]);
			if (plan.agreementdate.indexOf(reversedlastfiveyears[y])>-1) {
				newvalue = plan.organicpercent;
			}
		}
		returnData.push(newvalue)
	}

	//console.log(returnData)
	return returnData;
}

export default function Dashboard(props) {
	const classes = useStyles();
	const preventDefault = (event) => event.preventDefault();

	const { isAuthenticated, setIsAuthenticated, userData, setUserData } = useAuth();
	const [chainData, setChainData] = React.useState({
		locations: [],
		auditions: []
	});


	const [usagedata, setUsagedata] = React.useState(baseusagedata)

	const [news, setNews] = React.useState([]);

	const [isFetchingData, setIsFetchingData] = React.useState(true);
	const [helpModalOpen, setHelpModalOpen] = React.useState(false);
	const handleOpen = () => {
		setHelpModalOpen(true);
	};
	const handleClose = () => {
		setHelpModalOpen(false);
	};

	const [auditionHelpModalOpen, setAuditionHelpModalOpen] = React.useState(false);
	const handleAuditionHelpModalOpen = () => {
		setAuditionHelpModalOpen(true);
	};
	const handleAuditionHelpModalClose = () => {
		setAuditionHelpModalOpen(false);
	};


	


	const locations = chainData.locations.map((location) =>
		<p key={location.id}  className={classes.PaperTitle}>{location.name}<br/><span className={classes.muted}>{location.address}, {location.zip} {capitalizeFirstLetter(location.city)}</span><br/><span className={classes.muted}>{location.operatingsector==='public' ? 'Kaikille avoin ruokapaikka' : 'Yksityinen ruokapaikka'}</span></p>
	);

	const auditions = chainData.auditions.map((audition) =>
		<p key={audition.id} className={classes.PaperTitle}>Auditointi {audition.date}<br/><span className={classes.muted}>Hyväksytty {audition.agreeddate}</span></p>
	);

	const newsitems = news.map((item) =>
		<React.Fragment key={item.id}>
			<Grid container spacing={3}>
				<Grid item xs={3}>
					{item.img &&
						<img className={classes.newsLogo} src={'https://www.luomuravintola.fi' + item.img} alt=""/>	
					}
				</Grid>
				<Grid item xs={9}>
					<p className={classes.PaperTitle}>
						<span className={classes.muted}>{item.startdate}</span><br/>
						{item.name}<br/>
						<span className={classes.muted} dangerouslySetInnerHTML={{__html: item.text}} />
						{item.link &&
							<Link href={item.link} target="_blank">Lue lisää</Link>
						}
					</p>
				</Grid>
			</Grid>
			<hr/>
		</React.Fragment>
	);

	const deletePlanDraft = (plan) => {
		if (confirm("Haluatko varmasti poistaa luomusuunnitelman vedoksen '" + plan.createddate + "'?")) {
			setIsFetchingData(true)
			axios.post(plconfig.APIBase + 'apps/plan.groovy?action=delete', plan, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				//console.log('removing')
				if (response.data.success) {
					setChainData({
						...chainData,
						["draftplan"]: {}
					});
				}
			})
			.catch(function(error) {
			});
			setIsFetchingData(false)
		}
	}

	useEffect(() => {
		if (userData && userData.selectedChain && userData.selectedChain.id) {
			axios.post(plconfig.APIBase + 'apps/dashboard.groovy?action=chaininfo', {
				chainid: props.chainid
			}, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setIsFetchingData(false)
					setChainData(response.data.chain)
					//usagedata.datasets[0].data = updateUsageData(response.data.chain.agreedplans)
					//setUsagedata(usagedata)
				} else {
					setIsFetchingData(true)
				}
		
				return response.data;
			})
			.catch(function(error) {
				setIsFetchingData(true)
			});
		}
	}, [props.chainid])

	useEffect(() => {
		if (userData && userData.selectedChain && userData.selectedChain.id) {
			axios.post(plconfig.APIBase + 'apps/dashboard.groovy?action=chaingraph', {
				chainid: props.chainid
			}, { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					let ud = {...usagedata}
					ud.datasets[0].data = updateUsageData(response.data.chain.graphdata)
					setUsagedata(ud)
					//console.log(ud)
				}
				return response.data;
			})
			.catch(function(error) {
			});
		}
	}, [props.chainid])

	useEffect(() => {
		if (props.chainid) {
			axios.get('https://www.luomuravintola.fi/apps/news.groovy', { headers: { "Content-Type": "text/plain" } })
			.then(function (response) {
				if (response.data.success) {
					setNews(response.data.news)
				} else {
				}
			})
			.catch(function(error) {
			});
		}
	}, [props.chainid])
	
	return (
		<Container maxWidth="xl" className={classes.container}>

			{!isFetchingData &&
			<React.Fragment>
			
				<Grid container spacing={3} className={classes.topGrid}>
					<Grid item xs={4}>
						<Paper className={classes.paperbg}>
							<img className={classes.darklogo} src="logo_white.png" alt="Luomua ravintola logo"/>
							<p className={classes.pointNumberTitle}>ORGANISAATIO ON PORTAALLA</p>
							<p className={classes.pointNumber}>{chainData.step ? chainData.step : '0'}</p>
							<Grid container spacing={3}>
								{chainData.basicinfofilled
									? <Grid item xs={4}><CheckBoxIcon className={classes.doneIcon} /><p className={classes.doneTxt}>Perustiedot</p></Grid>
									: <Grid item xs={4}><CheckBoxOutlineBlankIcon className={classes.doneIcon} /><p className={classes.doneTxt}>Perustiedot</p></Grid>
								}
								{chainData.planfilled
									? <Grid item xs={4}><CheckBoxIcon className={classes.doneIcon} /><p className={classes.doneTxt}>Luomusuunnitelma</p></Grid>
									: <Grid item xs={4}><CheckBoxOutlineBlankIcon className={classes.doneIcon} /><p className={classes.doneTxt}>Luomusuunnitelma</p></Grid>
								}
								{chainData.kitchensfilled
									? <Grid item xs={4}><CheckBoxIcon className={classes.doneIcon} /><p className={classes.doneTxt}>Toimipaikat</p></Grid>
									: <Grid item xs={4}><CheckBoxOutlineBlankIcon className={classes.doneIcon} /><p className={classes.doneTxt}>Toimipaikat</p></Grid>
								}
							</Grid>
							<Button onClick={() => {window.open(plconfig.APIBase + 'apps/organisationoverview?c='+props.chainid, "_blank")}} variant="outlined" size="small" disableElevation className={classes.printBtn}>Yhteenveto</Button>
						</Paper>
					</Grid>
					<Grid item xs={8}>
						<h4 className={classes.PaperHeader}>Luomun käytön kehittyminen</h4>
						<Paper className={classes.paperTopGrid}>
							<IconButton className={classes.HelpIconButton} aria-label="Open help modal" onClick={handleOpen}><HelpIcon /></IconButton>
							<Line data={usagedata} options={usagedataoptions} redraw />
						</Paper>
						<Modal
							open={helpModalOpen}
							onClose={handleClose}
							aria-labelledby="Help modal for graph"
							aria-describedby="Help modal for graph"
						>
							<div className={classes.modalPaper}>
								<IconButton className={classes.modalCloseButton} onClick={handleClose}><CancelIcon /></IconButton>
								<p><strong>Toteutunut-viiva</strong> (vihreä) kertoo teidän omasta luomuosuuden käytön kehittymisestä vuosittain. Käyttötieto päivittyy luomusuunnitelmassa olevien tuotteiden perusteella. </p>
								<p><strong>Suositus-viiva</strong> (musta) kertoo Suomen hallituksen ammattikeittiöiden luomun käytön lisäämisen tavoitteen 25 % vuoteen 2030 mennessä (<a href="https://julkaisut.valtioneuvosto.fi/bitstream/handle/10024/162418/Liite%202%20strategiset%20vaikuttavuusmittarit.pdf?sequence=3&isAllowed=y" target="_blank">Julkisten hankintojen strategia, vaikuttavuusmittarit/ekologinen kestävyys</a>). </p>
								<p><strong>Ohjelman keskiarvo-viiva</strong> (sininen) kertoo Portaat luomuun -ohjelmassa olevien ruokapalveluiden luomuosuuden käytön keskiarvon. </p>
							</div>
						</Modal>
					</Grid>
				</Grid>


				<Grid container spacing={3}>
					<Grid item xs={6}>
						<h4 className={classes.PaperHeader}>Luomusuunnitelmat</h4>
						<Paper className={classes.paper}>

						{(chainData.currentplan) 
						? 
						<React.Fragment>

							{(chainData.currentplan && chainData.currentplan.createddate) &&
							<React.Fragment>
								<Grid container spacing={3}>
									<Grid item xs={6}>
										<p className={classes.PaperTitle}>Voimassa oleva luomusuunnitelma<br/><span className={classes.muted}>Muokattu {chainData.currentplan.modifiedddate}</span><br/><span className={classes.muted}>Hyväksytty {chainData.currentplan.agreementdate}</span></p>
									</Grid>
									<Grid item xs={2}><p className={classes.chartLabel}>Portaalle</p><p className={classes.chartNumber}>{chainData.currentplan.step}</p></Grid>
									<Grid item xs={2}><p className={classes.chartLabel}>Luomuprosentti</p><p className={classes.chartNumberPad}>{(chainData.currentplan.organicpercent>0) ? chainData.currentplan.organicpercent : '-'}<span className={classes.chartMini}>%</span></p></Grid>
									<Grid item xs={2}><p className={classes.chartLabel}>Porrastuotteita</p><p className={classes.chartNumberPad}>{chainData.currentplan.productcount}<span className={classes.chartMini}>kpl</span></p></Grid>
								</Grid>
								{chainData.currentplan.iswildmark &&
									<React.Fragment>
										<p className={classes.PaperTitle}><img className={classes.wildmarkimage} src="https://www.luomuravintola.fi/resources/images/luomua_ravintolat_luonnontuote_tunnus_v2.png" alt="Villituotelogo" /> <span className={classes.muted}><strong>Hienoa!</strong> Suunnitelmassa on käytössä myös villituotteita. Voit ladata merkin <Link href="https://www.luomuravintola.fi/resources/images//luomua_ravintolat_luonnontuote_tunnus_v2.jpg" target="_blank">JPG</Link> tai <Link href="https://www.luomuravintola.fi/resources/images//luomua_ravintolat_luonnontuote_tunnus_v2.pdf" target="_blank">PDF-tiedostona</Link></span></p>
									</React.Fragment>
								}
							</React.Fragment>
							}
							{(chainData.draftplan && chainData.draftplan.createddate) ?
							<React.Fragment>
							<hr/>
							<Grid container spacing={3}>
								<Grid item xs={6}>
									<p className={classes.PaperTitle}>Luomusuunnitelman vedos<br/><span className={classes.muted}>Muokattu {chainData.draftplan.modifiedddate}</span></p>
									{!chainData.draftplan.ready ?
									<React.Fragment>
										<LinkButton to='/plan' color="primary" variant="outlined" size="small" disableElevation>Jatka muokkausta</LinkButton>
										<Button className={classes.deletePlanBtn} variant="outlined" size="small" disableElevation onClick={(e) => {deletePlanDraft(chainData.draftplan)}}>Poista vedos</Button>
									</React.Fragment>
									: 
									<React.Fragment>
										<p className={classes.PaperTitle}><span className={classes.muted}>Luomusuunnitelma on lähetetty hyväksyttäväksi</span></p>
									</React.Fragment>
									}

									

								</Grid>
								<Grid item xs={2}><p className={classes.chartLabel}>Portaalle</p><p className={classes.chartNumber}>{chainData.draftplan.step}</p></Grid>
								<Grid item xs={2}><p className={classes.chartLabel}>Luomuprosentti</p><p className={classes.chartNumberPad}>{(chainData.draftplan.organicpercent>0) ? chainData.draftplan.organicpercent : '-'}<span className={classes.chartMini}>%</span></p></Grid>
								<Grid item xs={2}><p className={classes.chartLabel}>Porrastuotteita</p><p className={classes.chartNumberPad}>{chainData.draftplan.productcount}<span className={classes.chartMini}>kpl</span></p></Grid>
							</Grid>
							</React.Fragment>
							:
							<React.Fragment>
								<LinkButton to='/plan' color="primary" variant="outlined" size="small" disableElevation>Siirry luomusuunnitelmaan</LinkButton>
							</React.Fragment>
							}


						</React.Fragment>
						: <p className={classes.PaperTitle}>Ei luomusuunnitelmaa</p>
						}

							
						</Paper>
						<h4 className={classes.PaperHeader}>Toimipaikat ({chainData.locations.length})</h4>
						<Paper className={classes.paper}>
							{(chainData.locations && chainData.locations.length>2) 
							? <p className={classes.PaperTitle}>Useita toimipaikkoja</p>
							: locations
							}
							<LinkButton to='/locations' className={classes.cancelButton} variant="outlined" color="primary" size="small" disableElevation>Muokkaa toimipaikkoja</LinkButton>
						</Paper>
						<h4 className={classes.PaperHeader}>Auditoinnit</h4>
						<Paper className={classes.paper}>
							<IconButton className={classes.HelpIconButton} aria-label="Open audition modal" onClick={handleAuditionHelpModalOpen}><HelpIcon /></IconButton>
							{(chainData.auditions && chainData.auditions.length>0) 
							? auditions
							: <p className={classes.PaperTitle}>Ei auditointeja</p>
							}
						</Paper>
						<Modal
							open={auditionHelpModalOpen}
							onClose={handleAuditionHelpModalClose}
						>
							<div className={classes.modalPaper}>
								<IconButton className={classes.modalCloseButton} onClick={handleAuditionHelpModalClose}><CancelIcon /></IconButton>
								<p>Auditoinnin tarkoitus on vahvistaa Portaat luomuun- ohjelman luotettavuutta sekä kehittää edelleen ohjelmaa vastaamaan ammattikeittiöiden käytännön tarpeita. Auditoinnissa keskitytään kolmeen asiaan: tietojen paikkansapitävyyteen, luomun käytön luotettavuuteen, luomusta viestimiseen.</p>
								<p>Auditointikäyntiä varten voitte käydä läpi <a target="_blank" href="https://luomuravintola.fi/resources/pdf/tarkistuslista.pdf">tarkistuslistan</a>, joka helpottaa ja nopeuttaa asioiden käsittelyä auditointitilanteessa. Tarkistuslista auttaa myös luomusuunnitelman tietojen päivittämisessä.</p>
							</div>
						</Modal>

						<h4 className={classes.PaperHeader}>Materiaalit</h4>
						<Paper className={classes.paper}>
							<Grid container spacing={0}>
								<Grid item xs={8}>
									<Link className={classes.materialLink} href="https://www.luomuravintola.fi/tentti" target="_blank">Luomutesti</Link>
									<Link className={classes.materialLink} href="https://www.luomuravintola.fi/sesonkikuvat" target="_blank">Sesonkikuvat</Link>
									<Link className={classes.materialLink} href="https://www.luomuravintola.fi/tarkistuslista" target="_blank">Tarkistuslista</Link>
									<Link className={classes.materialLink} href="https://www.luomuravintola.fi/tiedotusaineistot" target="_blank">Tiedotusaineistot</Link>
									<Link className={classes.materialLink} href="https://www.luomuravintola.fi/Paivitysohjeet_luomusuunnitelma" target="_blank">Luomusuunnitelman päivitysohjeet</Link>
									<Link className={classes.materialLink} href="https://www.luomuravintola.fi/kayttoehdot" target="_blank">Käyttöehdot</Link>
									<Link className={classes.materialLink} href="https://www.luomuravintola.fi/yhteys" target="_blank">Yhteystiedot</Link>
								</Grid>
								<Grid item xs={4}>
									<Paper className={classes.banner}>
										<a href="https://www.ekoruokakone.fi" target="_blank"><img className={classes.bannerimage} src="https://www.luomuravintola.fi/resources/public//Ekoruokakone_banneri.png" alt="Ekoruokakone" /></a>
									</Paper>
									<Paper className={classes.banner2}>
										<a href={"https://plohjelma.bonsait.fi/exam/"+props.chainid} target="_blank"><Typography className={classes.bannertext}>Luomutesti</Typography></a>
									</Paper>
									
								</Grid>
							</Grid>	
						</Paper>


					</Grid>
					<Grid item xs={6}>
						<h4 className={classes.PaperHeader}>Ohjelma tiedottaa</h4>
						<Paper className={classes.paper}>
							{(news && news.length>0) 
							? newsitems
							: <p className={classes.PaperTitle}>Ei tiedotteita</p>
							}
						</Paper>
					</Grid>
				</Grid>
			
			</React.Fragment>
			}

		</Container>
	);
}